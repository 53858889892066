import React from 'react-dom';
import './appointment.css';

import emailjs from '@emailjs/browser';
import {AiOutlineWhatsApp, AiFillSchedule} from 'react-icons/ai'

import { AlertModal, /*addCurrentDate,*/ formatDateData } from '../../helperfunctions/Helper'

import { 
    AppointmentContainer,
    AppointmentWidget,
    ButtonList,
    ButtonNext,
    //ButtonClear,
    LargeInput,
    //LargeSelect, 
    TextArea,
    DivScrollerWidgetLg,
} from './AppointmentStyles';

import { 
    WidgetLgContainer,
    //FormScrollerWidgetLg,
    WidgetLgTitle,
} from '../../components/widgetlg/WidgetLgStyles';

import { 
    WidgetSmContainer,
    WidgetSmTitle,
    FormScrollerWidgetSm,
    WidgetSmTable,
    WidgetSmTh,
} from '../../components/widgetsm/WidgetSmStyles';

import {useState, useRef} from 'react';
import Axios from 'axios';

//set globale variables
var idProfileOne = '';
var scoreMatch = '';
var dateAppointment = '';
var timeAppointment = '';

function DeleteAppointment() {

    //alert
    /*const elementOutIdDays = "modaloutalertmanagedaysupdate";
    const elementDropIdDays = "backdropoutalertmanagedaysupdate";
    const buttonHandlerDays = "ouiHandlerAlertManageDaysUpdateBtn";*/

    //alert
    const elementOutId = "modaloutalertmanagedelete";
    const elementDropId = "backdropoutalertmanagedelete";
    const buttonHandler = "ouiHandlerAlertManageDeleteBtn";
    //alert create appointment
    const elementOutIdDeleteDetail = "modaloutalertdeletedetail";
    const elementDropIdDeleteDetail = "backdropoutalertdeletedetail";
    const buttonHandlerDeleteDetail = "ouiHandlerAlertDeleteDetailBtn";
    //alert send email and sms
    const elementOutIdDeleteSend = "modaloutalertdeletesend";
    const elementDropIdDeleteSend = "backdropoutalertdeletesend";
    const buttonHandlerDeleteSend = "ouiHandlerAlertDeleteSendBtn";

    //create list manage profile
    const [userManageDelete, setUserManageDelete] = useState([]);
    const [userDeleteDetail1, setUserDeleteDetail1] = useState([]);

    const idManageDeleteRef = useRef(null);
    const idManageDeleteUser1Ref = useRef(null);
    const usernameManageDeleteUser1Ref = useRef(null);
    const ageManageAppointUser1Ref = useRef(null);
    const locationManageAppointUser1Ref = useRef(null);
    const contactManageAppointUser1Ref = useRef(null);
    const emailManageAppointUser1Ref = useRef(null);
    const phoneManageAppointUser1Ref = useRef(null);
    //
    const formEmailDeleteUser1 = useRef(null);
    const idDeleteUserName1Ref = useRef(null);
    const idDeleteUser1EmailRef = useRef(null);
    const idDeleteUser1EmailSubjectRef = useRef(null);
    const deleteContentEmailUser1Ref = useRef(null);
    //
    const idDeletePhoneNumber1Ref = useRef(null);
    const deleteContentMessageUser1Ref = useRef(null);
    const idStarterDeleteUser1Ref = useRef(null);
    const idProfileDeleteUser1Ref = useRef(null);
    const scoreMatchDeleteUser1Ref = useRef(null);
    const dateDeleteUser1Ref = useRef(null);
    const timeDeleteUser1Ref = useRef(null);
    const phoneDeleteUser1Ref = useRef(null);
    const emailDeleteUser1Ref = useRef(null);

    async function viewDeleteAppointment(e){
        e.preventDefault();

        const viewdelete = document.getElementById('viewdelete');
        var resultat1 = 0; 
        var resultatResponse1 = [];

        await Axios.post('https://rishtadminapi.carions.net/manage/delete').then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTexteNotAvailable1 = "No Data Available";
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable1, buttonHandler);
                viewdelete.style.display = 'none';
            }else{
                //console.log(response1.data);//comment before deploying
                resultatResponse1 = response1.data;
                for(let i=0; i<resultatResponse1.length; i++){
                    idProfileOne = resultatResponse1[i].id_profile;
                    scoreMatch = resultatResponse1[i].score_match;
                    dateAppointment = resultatResponse1[i].date_appointment;
                    timeAppointment = resultatResponse1[i].time_appointment;
                }
                setUserManageDelete(response1.data);

                viewdelete.style.display = 'block';
            }
        })
    }

    async function idManageDeleteHandler(e){
        e.preventDefault();

        const deletedetail = document.getElementById('deletedetail');
        var resultat2 = 0;

        const valididManageDeleteRef = idManageDeleteRef.current.value;
        if(!valididManageDeleteRef){
            const pTexteNotUser1 = "Please Enter ID user 1";
            AlertModal(elementOutIdDeleteDetail, elementDropIdDeleteDetail, pTexteNotUser1, buttonHandlerDeleteDetail);
        }else{
            const IdManageDelete = idManageDeleteRef.current.value;

            //detail create user 1
            await Axios.post('https://rishtadminapi.carions.net/create/delete/detail1', {
                IdManageDelete:IdManageDelete,
            }).then((response2) => {
                resultat2 = response2.data.length;
                if(resultat2 === 0){
                    const pTexteNotAvailable2 = "No Data Available";
                    AlertModal(elementOutIdDeleteDetail, elementDropIdDeleteDetail, pTexteNotAvailable2, buttonHandlerDeleteDetail);
                    deletedetail.style.display = 'none';
                }else{
                    //console.log(response2.data);//comment before deploying
                    setUserDeleteDetail1(response2.data);
                    deletedetail.style.display = 'block';
                }
            })
        }
    }

    async function idEmailManageDeleteUser1Handler(e){
        e.preventDefault();

        const senddeleteemail1 = document.getElementById('senddeleteemail1');
        const senddeletesms1 = document.getElementById('senddeletesms1');
        const savedelete1 = document.getElementById('savedelete1');

        idDeleteUser1EmailRef.current.value = emailManageAppointUser1Ref.current.value;
        idDeleteUser1EmailSubjectRef.current.value = "Delete Appointment";
        idDeleteUserName1Ref.current.value = usernameManageDeleteUser1Ref.current.value;
        //idCreateUser1EmailFromRef.current.value = "iftiwed@gmail.com";

        senddeleteemail1.style.display = 'block';
        senddeletesms1.style.display = 'none';
        savedelete1.style.display = 'none';
    }

    async function idSmsManageDeleteUser1Handler(e){
        e.preventDefault();

        const senddeleteemail1 = document.getElementById('senddeleteemail1');
        const senddeletesms1 = document.getElementById('senddeletesms1');
        const savedelete1 = document.getElementById('savedelete1');

        //delete first number (0) from phone number
        var phoneNumber1 = phoneManageAppointUser1Ref.current.value;
        phoneNumber1 = phoneNumber1.substring(1);

        idDeletePhoneNumber1Ref.current.value = '+44'+phoneNumber1;

        senddeleteemail1.style.display = 'none';
        senddeletesms1.style.display = 'block';
        savedelete1.style.display = 'none';
    }

    async function saveDelete1(e){
        e.preventDefault();

        const senddeleteemail1 = document.getElementById('senddeleteemail1');
        const senddeletesms1 = document.getElementById('senddeletesms1');
        const savedelete1 = document.getElementById('savedelete1');

        idStarterDeleteUser1Ref.current.value = idManageDeleteUser1Ref.current.value;
        idProfileDeleteUser1Ref.current.value = idProfileOne;
        scoreMatchDeleteUser1Ref.current.value = scoreMatch;
        dateDeleteUser1Ref.current.value = formatDateData(dateAppointment);
        timeDeleteUser1Ref.current.value = timeAppointment;
        phoneDeleteUser1Ref.current.value = phoneManageAppointUser1Ref.current.value;
        emailDeleteUser1Ref.current.value = emailManageAppointUser1Ref.current.value;

        senddeleteemail1.style.display = 'none';
        senddeletesms1.style.display = 'none';
        savedelete1.style.display = 'block';
    }

    async function sendDeleteEmail1(e){
        e.preventDefault();

        const valIdDeleteContentEmailUser1 = deleteContentEmailUser1Ref.current.value;
        if(!valIdDeleteContentEmailUser1){
            const pTexteNotContent1 = "Please fill the content box";
            AlertModal(elementOutIdDeleteSend, elementDropIdDeleteSend, pTexteNotContent1, buttonHandlerDeleteSend); 
        }else{
            emailjs.sendForm('service_13h6qy4', 'template_fo0wb04', formEmailDeleteUser1.current, 'kexcSHxytC2s72ec3').then((result) => {
                //console.log(result.text);
                const pTexteSuccess1 = "Message sent successfully";
                AlertModal(elementOutIdDeleteSend, elementDropIdDeleteSend, pTexteSuccess1, buttonHandlerDeleteSend); 
            }, (error) => {
                //console.log(error.text);
                const pTexteError1 = "Send Message failed. Retry";
                AlertModal(elementOutIdDeleteSend, elementDropIdDeleteSend, pTexteError1, buttonHandlerDeleteSend); 
            });
            e.target.reset();
        }
    }

    async function sendDeleteSms1(e){
        e.preventDefault();

        const validIdDeletePhoneNumber1 = idDeletePhoneNumber1Ref.current.value;
        const validDeleteContentMessageUser1 = deleteContentMessageUser1Ref.current.value;
        if(!validIdDeletePhoneNumber1){
            const pTexteNotMessage1 = "Please enter Phone number";
            AlertModal(elementOutIdDeleteSend, elementDropIdDeleteSend, pTexteNotMessage1, buttonHandlerDeleteSend); 
        }else if(!validDeleteContentMessageUser1){
            const pTexteNotMessage1 = "Please fill the message box";
            AlertModal(elementOutIdDeleteSend, elementDropIdDeleteSend, pTexteNotMessage1, buttonHandlerDeleteSend); 
        }else{
            const IdDeletePhoneNumber1 = idDeletePhoneNumber1Ref.current.value;
            const DeleteContentMessageUser1 = deleteContentMessageUser1Ref.current.value;

            // Regex expression to remove all characters which are NOT alphanumeric 
            let number1 = IdDeletePhoneNumber1.replace(/[^\w\s]/gi, "").replace(/ /g, "");

            // Appending the phone number to the URL
            let url1 = `https://web.whatsapp.com/send?phone=${number1}`;

            // Appending the message to the URL by encoding it
            url1 += `&text=${encodeURI(DeleteContentMessageUser1)}&app_absent=0`;

            // Open our newly created URL in a new tab to send the message
            window.open(url1);
        }
    }

    async function validateDelete1(e){
        e.preventDefault();

        const senddeleteemail1 = document.getElementById('senddeleteemail1');
        const senddeletesms1 = document.getElementById('senddeletesms1');
        const savedelete1 = document.getElementById('savedelete1');

        const validDateDeleteUser1 = dateDeleteUser1Ref.current.value;
        const validTimeDeleteUser1 = timeDeleteUser1Ref.current.value;
        if(!validDateDeleteUser1){
            const pTexteNotMessage1 = "Please select new date";
            AlertModal(elementOutIdDeleteSend, elementDropIdDeleteSend, pTexteNotMessage1, buttonHandlerDeleteSend); 
        }else if(!validTimeDeleteUser1){
            const pTexteNotMessage1 = "Please select new time";
            AlertModal(elementOutIdDeleteSend, elementDropIdDeleteSend, pTexteNotMessage1, buttonHandlerDeleteSend); 
        }else{
            const IdStarterDeleteUser1 = idStarterDeleteUser1Ref.current.value;
            const DateDeleteUser1 = dateDeleteUser1Ref.current.value;
            const TimeDeleteUser1 = timeDeleteUser1Ref.current.value;

            await Axios.post('https://rishtadminapi.carions.net/create/delete/appointment', {
                IdStarterDeleteUser1:IdStarterDeleteUser1,
                DateDeleteUser1:DateDeleteUser1,
                TimeDeleteUser1:TimeDeleteUser1,
            }).then(() => {
                const pTexteSuccess1 = "Appointment deleted successfully";
                AlertModal(elementOutIdDeleteSend, elementDropIdDeleteSend, pTexteSuccess1, buttonHandlerDeleteSend); 
                senddeleteemail1.style.display = 'none';
                senddeletesms1.style.display = 'none';
                savedelete1.style.display = 'none';
            })
        }
    }

    return ( 
        <AppointmentContainer>
            <AppointmentWidget>
                <div id="userupdatemanage">
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>Outdated User Appointments</WidgetSmTitle>
                        <br/>
                        <div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <ButtonList onClick={viewDeleteAppointment}>View</ButtonList>
                            </div>
                        </div>
                        <div id="modaloutalertmanagedelete"></div>
                        <div id="backdropoutalertmanagedelete"></div>
                        <br/>
                        <div id='viewdelete'>
                            <FormScrollerWidgetSm>
                                <br/>
                                <WidgetSmTable>
                                    <thead>
                                        <tr className="widgetdatingTr">
                                            <WidgetSmTh>ID</WidgetSmTh>
                                            <WidgetSmTh>Date</WidgetSmTh>
                                            <WidgetSmTh>%Match</WidgetSmTh>
                                            <WidgetSmTh>Phone</WidgetSmTh>
                                            <WidgetSmTh>Email</WidgetSmTh>
                                        </tr>
                                    </thead>
                                    <tbody id='widgetSmTableTableBody'>
                                        {userManageDelete.map((val, i) => {
                                            return(
                                                <tr key={i}>
                                                    <td>{val.id_starter}</td>
                                                    <td>{formatDateData(val.date_appointment)}</td>
                                                    <td>{val.score_match}</td>
                                                    <td>{val.phone_starter}</td>
                                                    <td>{val.email_starter}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </WidgetSmTable>
                                <br/>
                                <div>
                                    <div style={{display:'block', textAlign:'center'}}>
                                        <LargeInput type='text' ref={idManageDeleteRef}  placeholder='Enter User ID'/>
                                    </div>
                                    <br/>
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <ButtonList onClick={idManageDeleteHandler}>Validate</ButtonList>
                                    </div>
                                </div>
                                <div id="modaloutalertdeletedetail"></div>
                                <div id="backdropoutalertdeletedetail"></div>
                            </FormScrollerWidgetSm>
                        </div>
                    </WidgetSmContainer>
                </div>

                <div id='deletedetail'>
                    <WidgetSmContainer id='widgetsm'>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                            <div style={{marginLeft:'15px'}}>
                                {userDeleteDetail1.map((valupdate1, j) => {
                                    return(
                                        <WidgetLgContainer id='widgetlg' key={j}>
                                            <WidgetLgTitle>User Delete details</WidgetLgTitle>
                                            <DivScrollerWidgetLg>
                                                <div>
                                                    <label>ID User </label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.id_starter} ref={idManageDeleteUser1Ref} />
                                                    </div>
                                                    <label>Username</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.username_starter} ref={usernameManageDeleteUser1Ref} />
                                                    </div>
                                                    <label>Age User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.age_starter} ref={ageManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Location User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.location_starter} ref={locationManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Prefered contact User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.contact_starter} ref={contactManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Email User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.email_starter} ref={emailManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Phone User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.phone_starter} ref={phoneManageAppointUser1Ref} />
                                                    </div>
                                                    <br/>
                                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idEmailManageDeleteUser1Handler}>Email</ButtonNext>
                                                        </div>

                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idSmsManageDeleteUser1Handler}>Sms</ButtonNext>
                                                        </div>

                                                        <div style={{marginRight:'15px'}}>
                                                            <ButtonList onClick={saveDelete1}>Validate</ButtonList>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div id='senddeleteemail1'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Send Email User</WidgetSmTitle>
                                                            <br/>
                                                            <FormScrollerWidgetSm ref={formEmailDeleteUser1} onSubmit={sendDeleteEmail1}>
                                                                <br/>
                                                                <div>
                                                                    <label>User Name</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly name="user_name" ref={idDeleteUserName1Ref} />
                                                                    </div>
                                                                    <label>User Email</label>
                                                                    <div>
                                                                        <LargeInput type="email" readOnly name="user_email" ref={idDeleteUser1EmailRef} />
                                                                    </div>
                                                                    <label>Subject</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly name="subject" ref={idDeleteUser1EmailSubjectRef} />
                                                                    </div>
                                                                    <label>Email Content</label>
                                                                    <div>
                                                                        <TextArea type="text" name="message" ref={deleteContentEmailUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList type='submit'>Send</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="senddeletesms1">
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Send Message User</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <AiOutlineWhatsApp style={{fontSize:'30px', color:'green'}} />
                                                                <br/>
                                                                <div>
                                                                    <label>User Phone number</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={idDeletePhoneNumber1Ref} />
                                                                    </div>
                                                                    <label>Message to User</label>
                                                                    <div>
                                                                        <TextArea type="text" ref={deleteContentMessageUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={sendDeleteSms1}>Send SMS</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id='savedelete1'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Delete User Appointment</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <AiFillSchedule style={{fontSize:'30px', color:'green'}}/>
                                                                <br/>
                                                                <div>
                                                                    <label>Id Starter User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={idStarterDeleteUser1Ref} />
                                                                    </div>
                                                                    <label>Id Profile User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={idProfileDeleteUser1Ref} />
                                                                    </div>
                                                                    <label>Score match User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={scoreMatchDeleteUser1Ref} />
                                                                    </div>
                                                                    <label>Date Appointment User</label>
                                                                    <div>
                                                                        <LargeInput type="date" readOnly ref={dateDeleteUser1Ref} />
                                                                    </div>
                                                                    <label>Time Appointment User</label>
                                                                    <div>
                                                                        <LargeInput type="time" readOnly ref={timeDeleteUser1Ref} />
                                                                    </div>
                                                                    <label>Phone User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={phoneDeleteUser1Ref} />
                                                                    </div>
                                                                    <label>Email User 1</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={emailDeleteUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={validateDelete1}>Delete</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="modaloutalertdeletesend"></div>
                                                    <div id="backdropoutalertdeletesend"></div>
                                                </div>
                                            </DivScrollerWidgetLg>
                                        </WidgetLgContainer>
                                    )
                                })}
                            </div>
                        </div>
                    </WidgetSmContainer>
                </div>
            </AppointmentWidget>
        </AppointmentContainer>
    );
}

export default DeleteAppointment;