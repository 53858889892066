import React from 'react-dom';
import './users.css';

import { RouteLink } from '../../helperfunctions/Helper';
import { AlertModal } from '../../helperfunctions/Helper'

import { 
    UserDiv,
    FormScroller, 
    TextDating,
    WidgetDatingTable,
    WidgetDatingTh, 
    LargeInput,
    ButtonList, 
    LargeSelect,
} from './UsersStyles';

import { useState, useRef} from "react";
import Axios from 'axios';

function Users() {

    //alert
    const elementOutId = "modaloutalertusers";
    const elementDropId = "backdropoutalertusers";
    const buttonHandler = "ouiHandlerAlertUsersBtn";

    //create list of user login data
    const [userList, setUserList] = useState([]);

    const useridRef = useRef(null);
    const CountryRef = useRef(null);

    async function userListStarter(e){
        e.preventDefault();

        const newuser = document.getElementById('newuser');
        var resultat = '';

        //clean input
        useridRef.current.value = "";

        const validCountry = CountryRef.current.value;
        if(!validCountry){
            const pTexteCountry = "Please select the country user currently live";
            AlertModal(elementOutId, elementDropId, pTexteCountry, buttonHandler);
        }else{
            const Country = CountryRef.current.value;
            await Axios.post('https://rishtadminapi.carions.net/get', {
                Country:Country,
            }).then((response) => {
                resultat = response.data.length;
                if(resultat === 0){
                    const pTexteNotAvailable = "No Data Available";
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                    newuser.style.display = 'none';
                }else{
                    newuser.style.display = 'block';
                    //console.log(response.data);
                    setUserList(response.data);
                }
            })
        }
    }

    //login handler
    async function updateHandler(event){
        event.preventDefault();

        const userId = useridRef.current.value;
        //console.log(userId)

        await Axios.put('https://rishtadminapi.carions.net/update', {
            userId:userId,
        }).then(() => {
            //callback function to route to link set.
            const sideHref = '/sidebar';
            RouteLink(event, sideHref);
            //alert('Click View to refresh');
            const notifyHref = '/notify';
            RouteLink(event, notifyHref);
        });
    }

    return ( 
        <UserDiv id='adminuserdiv'>
            <FormScroller>
                <br/>
                <TextDating>New Users List</TextDating>
                <br/>
                <div style={{display:'block',textAlign:'center'}}>
                    <div>
                        <LargeSelect id="country" name="country" ref={CountryRef}>
                            <option value=''>Living Country</option>
                            <option value='yes'>Only UK</option>
                            <option value='no'>Not UK</option>
                        </LargeSelect>
                    </div>
                </div>
                <br/>
                <div>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <ButtonList onClick={userListStarter}>View</ButtonList>
                    </div>
                </div>
                <WidgetDatingTable>
                    <thead>
                        <tr className="widgetdatingTr">
                            <WidgetDatingTh>ID</WidgetDatingTh>
                            <WidgetDatingTh>City</WidgetDatingTh>
                            <WidgetDatingTh>Username</WidgetDatingTh>
                            <WidgetDatingTh>Gender</WidgetDatingTh>
                            <WidgetDatingTh>Looking</WidgetDatingTh>
                            <WidgetDatingTh>from</WidgetDatingTh>
                            <WidgetDatingTh>Relationship</WidgetDatingTh>
                            <WidgetDatingTh>Score</WidgetDatingTh>
                        </tr>
                    </thead>
                    <tbody id='datingTableTableBody'>
                        {userList.map((val, i) => {
                            return(
                                <tr key={i}>
                                    <td>{val.id_starter}</td>
                                    <td>{val.location_starter}</td>
                                    <td>{val.username_starter}</td>
                                    <td>{val.sexe_starter}</td>
                                    <td>{val.sexefor_starter}</td>
                                    <td>{val.prefer_starter}</td>
                                    <td>{val.seek_starter}</td>
                                    <td>{val.score_starter}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </WidgetDatingTable>
                <br/>
                <div id='newuser'>
                    <div style={{display:'block', textAlign:'center'}}>
                        <LargeInput type='text' ref={useridRef}  placeholder='Enter User ID'/>
                    </div>
                    <br/>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <ButtonList onClick={updateHandler}>Validate New User</ButtonList>
                    </div>
                </div>
                <br/><br/>
                <div id="modaloutalertusers"></div>
                <div id="backdropoutalertusers"></div>
            </FormScroller>
        </UserDiv>
    );
}

export default Users;