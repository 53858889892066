import React from 'react-dom';
import './welcome.css';

import { AlertModal } from '../../helperfunctions/Helper'

import { 
    WelcomeContainer,
    WelcomeWidget,
    ButtonList,
    ButtonClear,
    LargeInput,
    LargeSelect, 
    TextArea,
} from './WelcomeStyles';

import { 
    WidgetLgContainer,
    FormScrollerWidgetLg,
    WidgetLgTitle,
} from '../../components/widgetlg/WidgetLgStyles';

import { 
    WidgetSmContainer,
    WidgetSmTitle,
    FormScrollerWidgetSm,
    WidgetSmTable,
    WidgetSmTh,
} from '../../components/widgetsm/WidgetSmStyles';

import {useState, useRef} from 'react';
import Axios from 'axios';

function Welcome() {

    //alert
    const elementOutId = "modaloutalertprofile";
    const elementDropId = "backdropoutalertprofile";
    const buttonHandler = "ouiHandlerAlertProfileBtn";
    //
    const elementOutIdProfiling = "modaloutalertprofiling";
    const elementDropIdProfiling = "backdropoutalertprofiling";
    const buttonHandlerProfiling = "ouiHandlerAlertProfilingBtn";

    //create list of user login data
    const [userReply, setUserReply] = useState([]);
    const [userProfileStatusReply, setUserProfileStatusReply] = useState([]);

    const CountryProfileRef = useRef(null);

    //useref id
    const userIdProfileStatusRef = useRef(null);
    //reply form ref
    const idProfileReplyRef = useRef(null);
    const idStarterProfileReplyRef = useRef(null);
    const userProfileReplyRef = useRef(null);
    const passwProfileReplyRef = useRef(null);
    const phoneProfileReplyRef = useRef(null);
    const originProfileReplyRef = useRef(null);
    const lookingProfileReplyRef = useRef(null);
    const memberProfileReplyRef = useRef(null);
    const seekingProfileReplyRef = useRef(null);
    const contactProfileReplyRef = useRef(null);
    const scoreProfileReplyRef = useRef(null);
    const processProfileReplyRef = useRef(null);
    const textAreaProfileReplyRef = useRef(null);

    async function welcomeListStatus(e){
        e.preventDefault();

        const validCountryProfile = CountryProfileRef.current.value;
        var resultat1 = 0;

        if(!validCountryProfile){
            const pTexteCountry = "Please select the country user currently live";
            AlertModal(elementOutId, elementDropId, pTexteCountry, buttonHandler);
        }else{
            const CountryProfile = CountryProfileRef.current.value;
            //console.log(CountryProfile);//comment before deploying
            await Axios.get('https://rishtadminapi.carions.net/repondre', {
                CountryProfile:CountryProfile,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    const pTexteNotAvailable = "No Data Available";
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                }else{
                    //console.log(response1.data);//comment before deploying
                    setUserReply(response1.data);
                }
            })
        }
    };

    async function idProfileHandler(e){
        e.preventDefault();

        const profiletouser = document.getElementById('profiletouser');
        var resultat2 = 0;

        const userIdProfileStatus = userIdProfileStatusRef.current.value;
        //console.log(userIdProfileStatus);//comment before deploying
        //const CountryProfile = CountryProfileRef.current.value;
        //console.log(CountryProfile);
        var countryStarter = [];
        var countrylive = '';

        await Axios.post('https://rishtadminapi.carions.net/profile/user', {
            userIdProfileStatus:userIdProfileStatus,
        }).then((response2) => {
            //console.log(response2.data);//comment before deploying
            //setUserProfileStatusReply(response2.data);
            countryStarter = response2.data;
        });
        //retrieve country_starter from table rishta_starter
        for(let i=0; i<countryStarter.length; i++){
            countrylive = countryStarter[i].country_starter;
            //console.log(countrylive);//comment before deploying
        }
        if((countrylive === 'UK') || (countrylive === 'United Kingdom')){
            //console.log('OK');//comment before deploying
            //select from rishta_reply where id_starter = ?
            await Axios.post('https://rishtadminapi.carions.net/idreply/profile/user', {
            userIdProfileStatus:userIdProfileStatus,
            }).then((response3) => {
                resultat2 = response3.data.length;
                if(resultat2 === 0){
                    const pTexteNotAvailableData = "No Data Available";
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailableData, buttonHandler);
                }else{
                    //console.log(response3.data);//comment before deploying
                    profiletouser.style.display = 'block';
                    setUserProfileStatusReply(response3.data);
                }
            });
        }else{
            //console.log('NO');//comment before deploying
            //select from rishta_reply where id_starter = ? and update status_reply to closed
            await Axios.post('https://rishtadminapi.carions.net/closereply/profile/user', {
            userIdProfileStatus:userIdProfileStatus,
            }).then((response4) => {
                const pTexteClosed = "Process closed because user not currently living in UK";
                AlertModal(elementOutId, elementDropId, pTexteClosed, buttonHandler);
                //console.log(response4.data);//comment before deploying
                setUserProfileStatusReply(response4.data);
                //countryStarter = response4.data;
            });
        }
    }

    async function clearProfileHandler(e){
        e.preventDefault();

        const profiletouser = document.getElementById('profiletouser');

        //clear the input for id
        userIdProfileStatusRef.current.value = '';
        //clear all input
        idProfileReplyRef.current.value = '';
        idStarterProfileReplyRef.current.value = '';
        userProfileReplyRef.current.value = '';
        passwProfileReplyRef.current.value = '';
        phoneProfileReplyRef.current.value = '';
        originProfileReplyRef.current.value = '';
        lookingProfileReplyRef.current.value = '';
        memberProfileReplyRef.current.value = '';
        seekingProfileReplyRef.current.value = ''; 
        contactProfileReplyRef.current.value = '';
        scoreProfileReplyRef.current.value = ''; 
        processProfileReplyRef.current.value = '';
        textAreaProfileReplyRef.current.value = '';

        profiletouser.style.display = 'none';
    }

    async function processProfileHandler(e){
        const processProfileReply = processProfileReplyRef.current.value;
        //console.log(processProfileReply);
        const profile = ["Thank you for your loyalty to Rishta. You are one step away from meeting your future soul mate who requires one last step to take before setting up an appointment at our premises very soon. Please fill out this form of your profile to allow us the right selection of the person who would suit you and finally meet him personally."];
        if(processProfileReply === 'profile'){
            textAreaProfileReplyRef.current.value = profile;
        }
    }

    async function sendProfileHandler(e){
        e.preventDefault();

        //check if some inputs empty
        const validprofileprocess = processProfileReplyRef.current.value;
        const validprofiletextarea = textAreaProfileReplyRef.current.value;
        if(!validprofileprocess){
            const pTexteProcessType = "Please select the process type";
            AlertModal(elementOutIdProfiling, elementDropIdProfiling, pTexteProcessType, buttonHandlerProfiling);
        }else if(!validprofiletextarea){
            //alert("Text Area is empty");
            const pTexteAreaEmpty = "Text Area is empty";
            AlertModal(elementOutIdProfiling, elementDropIdProfiling, pTexteAreaEmpty, buttonHandlerProfiling);
        }else{
            const idProfileReply = idProfileReplyRef.current.value;
            const idStarterProfileReply = idStarterProfileReplyRef.current.value;
            const userProfileReply = userProfileReplyRef.current.value;
            const passwProfileReply = passwProfileReplyRef.current.value;
            const phoneProfileReply = phoneProfileReplyRef.current.value;
            const origineProfileReply = originProfileReplyRef.current.value;
            const lookingProfileReply = lookingProfileReplyRef.current.value;
            const memberProfileReply = memberProfileReplyRef.current.value;
            const seekingProfileReply = seekingProfileReplyRef.current.value; 
            const contactProfileReply = contactProfileReplyRef.current.value;
            const scoreProfileReply = scoreProfileReplyRef.current.value; 
            const processProfileReply = processProfileReplyRef.current.value;
            const textAreaProfileReply = textAreaProfileReplyRef.current.value;

            Axios.post("https://rishtadminapi.carions.net/profile", {
                idProfileReply:idProfileReply,
                idStarterProfileReply:idStarterProfileReply,
                userProfileReply:userProfileReply,
                passwProfileReply:passwProfileReply,
                phoneProfileReply:phoneProfileReply,
                origineProfileReply:origineProfileReply,
                lookingProfileReply:lookingProfileReply,
                memberProfileReply:memberProfileReply,
                seekingProfileReply:seekingProfileReply,
                contactProfileReply:contactProfileReply,
                scoreProfileReply:scoreProfileReply,
                processProfileReply:processProfileReply,
                textAreaProfileReply:textAreaProfileReply,
            }).then(() => {
                const pTexteSuccessReply = "Profile user successful";
                AlertModal(elementOutIdProfiling, elementDropIdProfiling, pTexteSuccessReply, buttonHandlerProfiling);
                window.location.reload(false);
                //clear the input for id
                userIdProfileStatusRef.current.value = '';
                //clear all input
                idProfileReplyRef.current.value = '';
                idStarterProfileReplyRef.current.value = '';
                userProfileReplyRef.current.value = '';
                passwProfileReplyRef.current.value = '';
                phoneProfileReplyRef.current.value = '';
                originProfileReplyRef.current.value = '';
                lookingProfileReplyRef.current.value = '';
                memberProfileReplyRef.current.value = '';
                seekingProfileReplyRef.current.value = ''; 
                contactProfileReplyRef.current.value = '';
                scoreProfileReplyRef.current.value = ''; 
                processProfileReplyRef.current.value = '';
                textAreaProfileReplyRef.current.value = '';
            });
        }
    }

    return ( 
        <WelcomeContainer id='adminwelcome'>
            <WelcomeWidget>
                <div id="userprofilelist">
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>Request Members Profile</WidgetSmTitle>
                        <br/>
                        <div style={{display:'block',textAlign:'center'}}>
                            <div>
                                <LargeSelect id="countryprofile" name="countryprofile" ref={CountryProfileRef}>
                                    <option value=''>Living Country</option>
                                    <option value='yes'>Only UK</option>
                                    <option value='no'>Not UK</option>
                                </LargeSelect>
                            </div>
                        </div>
                        <br/>
                        <div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <ButtonList onClick={welcomeListStatus}>View</ButtonList>
                            </div>
                        </div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <WidgetSmTable>
                                <thead>
                                    <tr className="widgetdatingTr">
                                        <WidgetSmTh>ID</WidgetSmTh>
                                        <WidgetSmTh>Username</WidgetSmTh>
                                        <WidgetSmTh>Looking</WidgetSmTh>
                                        <WidgetSmTh>%Match</WidgetSmTh>
                                    </tr>
                                </thead>
                                <tbody id='widgetSmTableTableBody'>
                                    {userReply.map((val, i) => {
                                        return(
                                            <tr key={i}>
                                                <td>{val.id_starter}</td>
                                                <td>{val.username_starter}</td>
                                                <td>{val.sexefor_starter}</td>
                                                <td>{val.score_starter}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </WidgetSmTable>
                            <br/>
                            <div>
                                <div style={{display:'block', textAlign:'center'}}>
                                    <LargeInput type='text' ref={userIdProfileStatusRef}  placeholder='Enter User ID'/>
                                </div>
                                <br/>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <ButtonList onClick={idProfileHandler}>Validate</ButtonList>
                                </div>
                             </div>
                            <br/><br/>
                            <div id="modaloutalertprofile"></div>
                            <div id="backdropoutalertprofile"></div>
                        </FormScrollerWidgetSm>
                    </WidgetSmContainer>
                </div>
                
                <div id='profiletouser'>
                    {userProfileStatusReply.map((val, i) => {
                        return(
                            <WidgetLgContainer id='widgetlg' key={i}>
                                <WidgetLgTitle>Users Profile</WidgetLgTitle>
                                <FormScrollerWidgetLg>
                                    <div>
                                        <label>ID Reply</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.id_reply} ref={idProfileReplyRef} />
                                        </div>
                                        <label>ID Starter</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.id_starter} ref={idStarterProfileReplyRef} />
                                        </div>
                                        <label>Username</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.username_starter} ref={userProfileReplyRef} />
                                        </div>
                                        <label>Password</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.password_starter} ref={passwProfileReplyRef} />
                                        </div>
                                        <label>Phone number</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.phone_starter} ref={phoneProfileReplyRef} />
                                        </div>
                                        <label>User origin</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.origine_starter} ref={originProfileReplyRef} />
                                        </div>
                                        <label>Looking for</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.sexefor_starter} ref={lookingProfileReplyRef} />
                                        </div>
                                        <label>Member origin from</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.prefer_starter} ref={memberProfileReplyRef} />
                                        </div>
                                        <label>Seeking Relation</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.seek_starter} ref={seekingProfileReplyRef} />
                                        </div>
                                        <label>Prefered contact</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.contact_starter} ref={contactProfileReplyRef} />
                                        </div>
                                        <label>Dating match score</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={val.score_starter} ref={scoreProfileReplyRef} />
                                        </div>
                                    </div>
                                    <div>
                                        <label>Progress</label>
                                        <div>
                                            <LargeSelect id="process" name="process" ref={processProfileReplyRef} onChange={processProfileHandler}>
                                                <option value=''>Profile...</option>
                                                <option value='profile'>Add Profile</option>
                                            </LargeSelect>
                                        </div>
                                        <label>Text Content</label>
                                        <div>
                                            <TextArea ref={textAreaProfileReplyRef}></TextArea> 
                                        </div>
                                        <br/>
                                        <div style={{display:'flex', justifyContent:'space-around'}}>
                                            <div style={{display:'flex', justifyContent:'center'}}>
                                                <ButtonList onClick={sendProfileHandler}>Send</ButtonList>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center'}}>
                                                <ButtonClear onClick={clearProfileHandler}>Undo</ButtonClear>
                                            </div>
                                        </div>
                                    </div>
                                </FormScrollerWidgetLg>
                                <div id="modaloutalertprofiling"></div>
                                <div id="backdropoutalertprofiling"></div>
                            </WidgetLgContainer>
                        )
                    })};
                </div>
            </WelcomeWidget>
        </WelcomeContainer>
    );
}

export default Welcome;