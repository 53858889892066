import React from 'react-dom';
import './appointment.css';

import emailjs from '@emailjs/browser';
import {AiOutlineWhatsApp, AiFillSchedule} from 'react-icons/ai'

import { AlertModal, addCurrentDate, formatDateData } from '../../helperfunctions/Helper'

import { 
    AppointmentContainer,
    AppointmentWidget,
    ButtonList,
    ButtonNext,
    //ButtonClear,
    LargeInput,
    LargeSelect, 
    TextArea,
    DivScrollerWidgetLg,
} from './AppointmentStyles';

import { 
    WidgetLgContainer,
    //FormScrollerWidgetLg,
    WidgetLgTitle,
} from '../../components/widgetlg/WidgetLgStyles';

import { 
    WidgetSmContainer,
    WidgetSmTitle,
    FormScrollerWidgetSm,
    WidgetSmTable,
    WidgetSmTh,
} from '../../components/widgetsm/WidgetSmStyles';

import {useState, useRef} from 'react';
import Axios from 'axios';

//set globale variables
var idProfileOne = '';
var scoreMatch = '';
var dateAppointment = '';
var timeAppointment = '';

function Reminder() {

    //alert
    const elementOutIdDays = "modaloutalertmanagedaysreminder";
    const elementDropIdDays = "backdropoutalertmanagedaysreminder";
    const buttonHandlerDays = "ouiHandlerAlertManageDaysReminderBtn";

    //alert
    const elementOutId = "modaloutalertmanagereminder";
    const elementDropId = "backdropoutalertmanagereminder";
    const buttonHandler = "ouiHandlerAlertManageReminderBtn";
    //alert create appointment
    const elementOutIdRemindDetail = "modaloutalertreminddetail";
    const elementDropIdRemindDetail = "backdropoutalertreminddetail";
    const buttonHandlerRemindDetail = "ouiHandlerAlertRemindDetailBtn";
    //alert send email and sms
    const elementOutIdRemindSend = "modaloutalertremindsend";
    const elementDropIdRemindSend = "backdropoutalertremindsend";
    const buttonHandlerRemindSend = "ouiHandlerAlertRemindSendBtn";

    //create list manage profile
    const [userManageReminder, setUserManageReminder] = useState([]);
    const [userReminderDetail1, setUserReminderDetail1] = useState([]);

    const numberDaysRef = useRef(null);
    const newDateRemindRef = useRef(null);
    //
    const idManageRemindRef = useRef(null);
    //
    const idManageRemindUser1Ref = useRef(null);
    const usernameManageRemindUser1Ref = useRef(null);
    const ageManageAppointUser1Ref = useRef(null);
    const locationManageAppointUser1Ref = useRef(null);
    const contactManageAppointUser1Ref = useRef(null);
    const emailManageAppointUser1Ref = useRef(null);
    const phoneManageAppointUser1Ref = useRef(null);
    //
    const formEmailRemindUser1 = useRef(null);
    const idRemindUserName1Ref = useRef(null);
    const idRemindUser1EmailRef = useRef(null);
    const idRemindUser1EmailSubjectRef = useRef(null);
    const remindContentEmailUser1Ref = useRef(null);
    const idRemindPhoneNumber1Ref = useRef(null);
    const remindContentMessageUser1Ref = useRef(null);
    const idStarterRemindUser1Ref = useRef(null);
    const idProfileRemindUser1Ref = useRef(null);
    const scoreMatchRemindUser1Ref = useRef(null);
    const dateRemindUser1Ref = useRef(null);
    const timeRemindUser1Ref = useRef(null);
    const phoneRemindUser1Ref = useRef(null);
    const emailRemindUser1Ref = useRef(null);


    async function viewReminderAppointment(e){
        e.preventDefault();

        const viewreminder = document.getElementById('viewreminder');

        const validnumberDays = numberDaysRef.current.value;
        if(!validnumberDays){
            const pTexteSelectDays = "Please select number days";
            AlertModal(elementOutIdDays, elementDropIdDays, pTexteSelectDays, buttonHandlerDays);
        }else{
            var numberDays = parseFloat(numberDaysRef.current.value);
            var remindDate = addCurrentDate(numberDays);
            newDateRemindRef.current.value = remindDate;
            const NewDateRemind = newDateRemindRef.current.value;
            //console.log(remindDate);//comment before deploying
            var resultat1 = 0; 
            var resultatResponse1 = [];

            await Axios.post('https://rishtadminapi.carions.net/manage/reminder', {
                NewDateRemind: NewDateRemind,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    const pTexteNotAvailable1 = "No Data Available";
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable1, buttonHandler);
                    viewreminder.style.display = 'none';
                }else{
                    //console.log(response1.data);//comment before deploying
                    resultatResponse1 = response1.data;
                    for(let i=0; i<resultatResponse1.length; i++){
                        idProfileOne = resultatResponse1[i].id_profile;
                        scoreMatch = resultatResponse1[i].score_match;
                        dateAppointment = resultatResponse1[i].date_appointment;
                        timeAppointment = resultatResponse1[i].time_appointment;
                    }
                    setUserManageReminder(response1.data);

                    viewreminder.style.display = 'block';
                }
            })
        }
    }

    async function idManageRemindHandler(e){
        e.preventDefault();

        const reminderdetail = document.getElementById('reminderdetail');
        var resultat2 = 0;

        const valididManageRemindRef = idManageRemindRef.current.value;
        if(!valididManageRemindRef){
            const pTexteNotUser1 = "Please Enter ID user 1";
            AlertModal(elementOutIdRemindDetail, elementDropIdRemindDetail, pTexteNotUser1, buttonHandlerRemindDetail);
        }else{
            const IdManageRemind = idManageRemindRef.current.value;

            //detail create user 1
            await Axios.post('https://rishtadminapi.carions.net/create/remind/detail1', {
                IdManageRemind:IdManageRemind,
            }).then((response2) => {
                resultat2 = response2.data.length;
                if(resultat2 === 0){
                    const pTexteNotAvailable2 = "No Data Available";
                    AlertModal(elementOutIdRemindDetail, elementDropIdRemindDetail, pTexteNotAvailable2, buttonHandlerRemindDetail);
                    reminderdetail.style.display = 'none';
                }else{
                    //console.log(response2.data);//comment before deploying
                    setUserReminderDetail1(response2.data);
                    reminderdetail.style.display = 'block';
                }
            })
        }
    }

    async function idEmailManageRemindUser1Handler(e){
        e.preventDefault();

        const sendremindemail1 = document.getElementById('sendremindemail1');
        const sendremindsms1 = document.getElementById('sendremindsms1');
        const saveremind1 = document.getElementById('saveremind1');

        idRemindUser1EmailRef.current.value = emailManageAppointUser1Ref.current.value;
        idRemindUser1EmailSubjectRef.current.value = "Reminder";
        idRemindUserName1Ref.current.value = usernameManageRemindUser1Ref.current.value;
        //idCreateUser1EmailFromRef.current.value = "iftiwed@gmail.com";

        sendremindemail1.style.display = 'block';
        sendremindsms1.style.display = 'none';
        saveremind1.style.display = 'none';
    }

    async function idSmsManageRemindUser1Handler(e){
        e.preventDefault();

        const sendremindemail1 = document.getElementById('sendremindemail1');
        const sendremindsms1 = document.getElementById('sendremindsms1');
        const saveremind1 = document.getElementById('saveremind1');

        //delete first number (0) from phone number
        var phoneNumber1 = phoneManageAppointUser1Ref.current.value;
        phoneNumber1 = phoneNumber1.substring(1);

        idRemindPhoneNumber1Ref.current.value = '+44'+phoneNumber1;

        sendremindemail1.style.display = 'none';
        sendremindsms1.style.display = 'block';
        saveremind1.style.display = 'none';
    }

    async function saveRemind1(e){
        e.preventDefault();

        const sendremindemail1 = document.getElementById('sendremindemail1');
        const sendremindsms1 = document.getElementById('sendremindsms1');
        const saveremind1 = document.getElementById('saveremind1');

        idStarterRemindUser1Ref.current.value = idManageRemindUser1Ref.current.value;
        idProfileRemindUser1Ref.current.value = idProfileOne;
        scoreMatchRemindUser1Ref.current.value = scoreMatch;
        dateRemindUser1Ref.current.value = formatDateData(dateAppointment);
        timeRemindUser1Ref.current.value = timeAppointment;
        phoneRemindUser1Ref.current.value = phoneManageAppointUser1Ref.current.value;
        emailRemindUser1Ref.current.value = emailManageAppointUser1Ref.current.value;

        sendremindemail1.style.display = 'none';
        sendremindsms1.style.display = 'none';
        saveremind1.style.display = 'block';
    }

    async function sendRemindEmail1(e){
        e.preventDefault();

        const valIdremindContentEmailUser1 = remindContentEmailUser1Ref.current.value;
        if(!valIdremindContentEmailUser1){
            const pTexteNotContent1 = "Please fill the content box";
            AlertModal(elementOutIdRemindSend, elementDropIdRemindSend, pTexteNotContent1, buttonHandlerRemindSend); 
        }else{
            emailjs.sendForm('service_13h6qy4', 'template_fo0wb04', formEmailRemindUser1.current, 'kexcSHxytC2s72ec3').then((result) => {
                //console.log(result.text);
                const pTexteSuccess1 = "Message sent successfully";
                AlertModal(elementOutIdRemindSend, elementDropIdRemindSend, pTexteSuccess1, buttonHandlerRemindSend); 
            }, (error) => {
                //console.log(error.text);
                const pTexteError1 = "Send Message failed. Retry";
                AlertModal(elementOutIdRemindSend, elementDropIdRemindSend, pTexteError1, buttonHandlerRemindSend); 
            });
            e.target.reset();
        }
    }

    async function sendRemindSms1(e){
        e.preventDefault();

        const validIdRemindPhoneNumber1 = idRemindPhoneNumber1Ref.current.value;
        const validRemindContentMessageUser1 = remindContentMessageUser1Ref.current.value;
        if(!validIdRemindPhoneNumber1){
            const pTexteNotMessage1 = "Please enter Phone number";
            AlertModal(elementOutIdRemindSend, elementDropIdRemindSend, pTexteNotMessage1, buttonHandlerRemindSend); 
        }else if(!validRemindContentMessageUser1){
            const pTexteNotMessage1 = "Please fill the message box";
            AlertModal(elementOutIdRemindSend, elementDropIdRemindSend, pTexteNotMessage1, buttonHandlerRemindSend); 
        }else{
            const IdRemindPhoneNumber1 = idRemindPhoneNumber1Ref.current.value;
            const RemindContentMessageUser1 = remindContentMessageUser1Ref.current.value;

            // Regex expression to remove all characters which are NOT alphanumeric 
            let number1 = IdRemindPhoneNumber1.replace(/[^\w\s]/gi, "").replace(/ /g, "");

            // Appending the phone number to the URL
            let url1 = `https://web.whatsapp.com/send?phone=${number1}`;

            // Appending the message to the URL by encoding it
            url1 += `&text=${encodeURI(RemindContentMessageUser1)}&app_absent=0`;

            // Open our newly created URL in a new tab to send the message
            window.open(url1);
        }
    }

    /*async function validateRemind1(e){
        //e.preventDefault();

        //
    }*/

    return ( 
        <AppointmentContainer>
            <AppointmentWidget>
                <div id="userremindermanage">
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>User Appointments Reminder</WidgetSmTitle>
                        <br/>
                        <div style={{display:'block',textAlign:'center'}}>
                            <div>
                                <LargeSelect id="numberdays" name="numberdays" ref={numberDaysRef}>
                                    <option value=''>Select days number</option>
                                    <option value='1'>1 day</option>
                                    <option value='2'>2 days</option>
                                    <option value='3'>3 days</option>
                                    <option value='4'>4 days</option>
                                    <option value='5'>5 days</option>
                                    <option value='6'>6 days</option>
                                    <option value='7'>7 days</option>
                                </LargeSelect>
                            </div>
                            <div style={{display:'none'}}>
                                <LargeInput type="date" readOnly ref={newDateRemindRef} />
                            </div>
                        </div>
                        <br/>
                        <div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <ButtonList onClick={viewReminderAppointment}>View</ButtonList>
                            </div>
                        </div>

                        <div id="modaloutalertmanagedaysreminder"></div>
                        <div id="backdropoutalertmanagedaysreminder"></div>

                        <div id="modaloutalertmanagereminder"></div>
                        <div id="backdropoutalertmanagereminder"></div>
                        <br/>
                        <div id='viewreminder'>
                            <FormScrollerWidgetSm>
                                <br/>
                                <WidgetSmTable>
                                    <thead>
                                        <tr className="widgetdatingTr">
                                            <WidgetSmTh>ID</WidgetSmTh>
                                            <WidgetSmTh>Date</WidgetSmTh>
                                            <WidgetSmTh>%Match</WidgetSmTh>
                                            <WidgetSmTh>Phone</WidgetSmTh>
                                            <WidgetSmTh>Email</WidgetSmTh>
                                        </tr>
                                    </thead>
                                    <tbody id='widgetSmTableTableBody'>
                                        {userManageReminder.map((val, i) => {
                                            return(
                                                <tr key={i}>
                                                    <td>{val.id_starter}</td>
                                                    <td>{formatDateData(val.date_appointment)}</td>
                                                    <td>{val.score_match}</td>
                                                    <td>{val.phone_starter}</td>
                                                    <td>{val.email_starter}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </WidgetSmTable>
                                <br/>
                                <div>
                                    <div style={{display:'block', textAlign:'center'}}>
                                        <LargeInput type='text' ref={idManageRemindRef}  placeholder='Enter User ID'/>
                                    </div>
                                    <br/>
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <ButtonList onClick={idManageRemindHandler}>Validate</ButtonList>
                                    </div>
                                </div>
                                <div id="modaloutalertreminddetail"></div>
                                <div id="backdropoutalertreminddetail"></div>
                            </FormScrollerWidgetSm>
                        </div>
                    </WidgetSmContainer>
                </div>

                <div id='reminderdetail'>
                    <WidgetSmContainer id='widgetsm'>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                            <div style={{marginLeft:'15px'}}>
                            {userReminderDetail1.map((valremind1, j) => {
                                    return(
                                        <WidgetLgContainer id='widgetlg' key={j}>
                                            <WidgetLgTitle>User Reminder details</WidgetLgTitle>
                                            <DivScrollerWidgetLg>
                                                <div>
                                                    <label>ID User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valremind1.id_starter} ref={idManageRemindUser1Ref} />
                                                    </div>
                                                    <label>Username</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valremind1.username_starter} ref={usernameManageRemindUser1Ref} />
                                                    </div>
                                                    <label>Age User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valremind1.age_starter} ref={ageManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Location User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valremind1.location_starter} ref={locationManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Prefered contact User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valremind1.contact_starter} ref={contactManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Email User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valremind1.email_starter} ref={emailManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Phone User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valremind1.phone_starter} ref={phoneManageAppointUser1Ref} />
                                                    </div>
                                                    <br/>
                                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idEmailManageRemindUser1Handler}>Email</ButtonNext>
                                                        </div>

                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idSmsManageRemindUser1Handler}>Sms</ButtonNext>
                                                        </div>

                                                        <div style={{marginRight:'15px'}}>
                                                            <ButtonList onClick={saveRemind1}>Check</ButtonList>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div id='sendremindemail1'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Send Email User</WidgetSmTitle>
                                                            <br/>
                                                            <FormScrollerWidgetSm ref={formEmailRemindUser1} onSubmit={sendRemindEmail1}>
                                                                <br/>
                                                                <div>
                                                                    <label>User Name</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly name="user_name" ref={idRemindUserName1Ref} />
                                                                    </div>
                                                                    <label>User Email</label>
                                                                    <div>
                                                                        <LargeInput type="email" readOnly name="user_email" ref={idRemindUser1EmailRef} />
                                                                    </div>
                                                                    <label>Subject</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly name="subject" ref={idRemindUser1EmailSubjectRef} />
                                                                    </div>
                                                                    <label>Email Content</label>
                                                                    <div>
                                                                        <TextArea type="text" name="message" ref={remindContentEmailUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList type='submit'>Send</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="sendremindsms1">
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Send Message User</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <AiOutlineWhatsApp style={{fontSize:'30px', color:'green'}} />
                                                                <br/>
                                                                <div>
                                                                    <label>User Phone number</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={idRemindPhoneNumber1Ref} />
                                                                    </div>
                                                                    <label>Message to User</label>
                                                                    <div>
                                                                        <TextArea type="text" ref={remindContentMessageUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={sendRemindSms1}>Send SMS</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id='saveremind1'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Confirm User Appointment</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <AiFillSchedule style={{fontSize:'30px', color:'green'}}/>
                                                                <br/>
                                                                <div>
                                                                    <label>Id Starter User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={idStarterRemindUser1Ref} />
                                                                    </div>
                                                                    <label>Id Profile User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={idProfileRemindUser1Ref} />
                                                                    </div>
                                                                    <label>Score match User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={scoreMatchRemindUser1Ref} />
                                                                    </div>
                                                                    <label>Date Appointment User</label>
                                                                    <div>
                                                                        <LargeInput type="date" readOnly ref={dateRemindUser1Ref} />
                                                                    </div>
                                                                    <label>Time Appointment User</label>
                                                                    <div>
                                                                        <LargeInput type="time" readOnly ref={timeRemindUser1Ref} />
                                                                    </div>
                                                                    <label>Phone User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={phoneRemindUser1Ref} />
                                                                    </div>
                                                                    <label>Email User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={emailRemindUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div style={{display:'none'}}>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList>Validate</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="modaloutalertremindsend"></div>
                                                    <div id="backdropoutalertremindsend"></div>
                                                </div>
                                            </DivScrollerWidgetLg>
                                        </WidgetLgContainer>
                                    )
                                })}
                            </div>
                        </div>
                    </WidgetSmContainer>
                </div>
            </AppointmentWidget>
        </AppointmentContainer>
    );
}

export default Reminder;