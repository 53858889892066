import styled from 'styled-components';

export const WidgetSmContainer = styled.div`
    flex:1;
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #000000;
    padding:20px;
    margin-right:20px;
`;

export const WidgetSmTitle = styled.h3`
    font-size:20px;
    font-weight:600;
`;

export const FormScrollerWidgetSm = styled.form`
    width: 100%;
    height: 460px;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple red;
    scrollbar-width: thin;
`;

export const WidgetSmTable = styled.table`
    width:100%;
    border-spacing:20px;
`;

export const WidgetSmTh = styled.th`
    text-align:left;
    color:#000;
`;

/*export const WidgetSmImage = styled.img`
    width:40px;
    height:40px;
    border-radius:50%;
    object-fit:cover;
`;

export const WidgetSmList = styled.ul`
    margin:0;
    padding:0;
    list-style:none;
`;

export const WidgetSmListItem = styled.li`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin:20px 0px;
`;

export const WidgetSmUser = styled.div`
    display:flex;
    flex-direction:column;
`;

export const WidgetSmUsername = styled.span`
    font-weight:600;
`;

export const WidgetSmUserTitle = styled.span`
    font-weight:300;
`;

export const WidgetSmButton = styled.button`
    display:flex;
    align-items:center;
    border:none;
    border-radius:10px;
    padding:7px 10px;
    background-color:#eeeef7;
    color:#555;
    cursor:pointer;
`;*/

