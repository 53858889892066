import React from 'react-dom';
import './sidebar.css';

import Link from '../Link';

import { 
    MdLineStyle, 
    MdTimeline, 
    MdTrendingUp, 
    MdOutlineStorefront,
    MdOutlineDynamicFeed, 
    MdManageSearch, 
    MdTableChart,
    MdAccessibilityNew,
    MdOutlineMeetingRoom,
    //MdOutlineReplyAll,
} from 'react-icons/md';

import {FaRegUser} from 'react-icons/fa';
import {BiPound, BiBarChart, BiMessage} from 'react-icons/bi';
import {AiOutlineMail, AiOutlineProfile} from 'react-icons/ai';

import { 
    SideBar, 
    //SideBarWrapper, 
    SideBarMenu, 
    SideBarTitle, 
    SideBarList, 
    SideBarListItem, 
} from './SidebarStyles';

function Sidebar() {
    return ( 
        <SideBar id='adminsidebar'>
            <SideBarMenu>
                <SideBarTitle>Dashboard</SideBarTitle>
                <SideBarList>
                    <SideBarListItem id='sidebarlisthome' className='sidebarlisthome active'>
                        <MdLineStyle className='sidebarIcon'/>
                        <Link href="/home" className="item">
                            Home
                        </Link>
                    </SideBarListItem >

                    <SideBarListItem>
                        <MdTimeline className='sidebarIcon'/>
                        <Link href="/analytics" className="item">
                            Analytics
                        </Link>
                    </SideBarListItem>

                    <SideBarListItem>
                        <MdTrendingUp className='sidebarIcon'/>
                        <Link href="/sales" className="item">
                            Sales
                        </Link>
                    </SideBarListItem>
                </SideBarList>
            </SideBarMenu>

            <SideBarMenu>
                <SideBarTitle>Quick Menu</SideBarTitle>
                <SideBarList>
                    <SideBarListItem id='theusers'>
                        <FaRegUser className='sidebarIcon'/>
                        <Link href="/users" className="item">
                            Users
                        </Link>
                    </SideBarListItem >

                    <SideBarListItem>
                        <BiMessage className='sidebarIcon'/>
                        <Link href="/notify" className="item">
                            Notify
                        </Link>
                    </SideBarListItem>

                    {/*<SideBarListItem>
                        <MdOutlineReplyAll className='sidebarIcon'/>
                        <Link href="/replying" className="item">
                            Reply
                        </Link>
                    </SideBarListItem>*/}

                    <SideBarListItem>
                        <MdAccessibilityNew className='sidebarIcon'/>
                        <Link href="welcome" className="item">
                            Welcome
                        </Link>
                    </SideBarListItem>

                    <SideBarListItem>
                        <AiOutlineProfile className='sidebarIcon'/>
                        <Link href="profile" className="item">
                            Profile
                        </Link>
                    </SideBarListItem>

                    <SideBarListItem>
                        <MdOutlineMeetingRoom className='sidebarIcon'/>
                        <Link href="appointment" className="item">
                            Appointment
                        </Link>
                    </SideBarListItem>

                    <SideBarListItem>
                        <BiBarChart className='sidebarIcon'/>
                        <Link href="reports-users" className="item">
                            Reports
                        </Link>
                    </SideBarListItem>

                    <SideBarListItem>
                        <MdOutlineStorefront className='sidebarIcon'/>
                        <Link href="products" className="item">
                            Products
                        </Link>
                    </SideBarListItem>

                    <SideBarListItem>
                        <BiPound className='sidebarIcon'/>
                        <Link href="/transactions" className="item">
                            Transactions
                        </Link>
                    </SideBarListItem>

                    <SideBarListItem>
                        <BiBarChart className='sidebarIcon'/>
                        <Link href="/reports" className="item">
                            Reports
                        </Link>
                    </SideBarListItem>
                </SideBarList>
            </SideBarMenu>

            <SideBarMenu>
                <SideBarTitle>Notifications</SideBarTitle>
                <SideBarList>
                    <SideBarListItem>
                        <AiOutlineMail className='sidebarIcon'/>
                        <Link href="/mail" className="item">
                            Mail
                        </Link>
                    </SideBarListItem >

                    <SideBarListItem>
                        <MdOutlineDynamicFeed className='sidebarIcon'/>
                        <Link href="/feedback" className="item">
                            Feedback
                        </Link>
                    </SideBarListItem>

                    <SideBarListItem>
                        <BiMessage className='sidebarIcon'/>
                        <Link href="/messages" className="item">
                            Messages
                        </Link>
                    </SideBarListItem>
                </SideBarList>
            </SideBarMenu>

            <SideBarMenu>
                <SideBarTitle>Staff</SideBarTitle>
                <SideBarList>
                    <SideBarListItem id='theemployees'>
                        <FaRegUser className='sidebarIcon'/>
                        <Link href="/employees" className="item">
                            Employees
                        </Link>
                    </SideBarListItem >

                    <SideBarListItem>
                        <MdManageSearch className='sidebarIcon'/>
                        <Link href="/manage-staff" className="item">
                            Manage
                        </Link>
                    </SideBarListItem >

                    <SideBarListItem>
                        <MdTimeline className='sidebarIcon'/>
                        <Link href="/analytics-staff" className="item">
                            Analytics
                        </Link>
                    </SideBarListItem>

                    <SideBarListItem>
                        <MdTableChart className='sidebarIcon'/>
                        <Link href="/reports-staff" className="item">
                            Reports
                        </Link>
                    </SideBarListItem>
                </SideBarList>
            </SideBarMenu>
        </SideBar>
    );
}

export default Sidebar;