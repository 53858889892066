import React from 'react-dom';
import './profile.css';

import { AlertModal, checkSimilarity } from '../../helperfunctions/Helper'

import { 
    ProfileContainer,
    ProfileWidget,
    ButtonList,
    ButtonNext,
    ButtonClear,
    LargeInput,
    //LargeSelect, 
    TextArea,
    DivScrollerWidgetLg,
} from './ProfileStyles';

import { 
    WidgetLgContainer,
    //FormScrollerWidgetLg,
    WidgetLgTitle,
} from '../../components/widgetlg/WidgetLgStyles';

import { 
    WidgetSmContainer,
    WidgetSmTitle,
    FormScrollerWidgetSm,
    WidgetSmTable,
    WidgetSmTh,
} from '../../components/widgetsm/WidgetSmStyles';

import {useState, useRef} from 'react';
import Axios from 'axios';

function Profile() {

    //alert
    const elementOutId = "modaloutalertmanageprofile";
    const elementDropId = "backdropoutalertmanageprofile";
    const buttonHandler = "ouiHandlerAlertManageProfileBtn";
    //
    const elementOutIdMatch = "modaloutalertmanageprofilematch";
    const elementDropIdMatch = "backdropoutalertmanageprofilematch";
    const buttonHandlerMatch = "ouiHandlerAlertManageProfileMatchBtn";

    //
    const elementOutIdSave = "modaloutalertmanageprofilesave";
    const elementDropIdSave = "backdropoutalertmanageprofilesave";
    const buttonHandlerSave = "ouiHandlerAlertManageProfileSaveBtn";

    //create list manage profile
    const [userManageProfile, setUserManageProfile] = useState([]);
    const [userManageProfileDetail, setUserManageProfileDetail] = useState([]);

    //create list manage profile
    const [userManageProfileMatch, setUserManageProfileMatch] = useState([]);
    const [userManageProfileDetailMatch, setUserManageProfileDetailMatch] = useState([]);

    //id useref
    const idManageProfileRef = useRef(null);
    const idmanageProfileDetailStarterRef = useRef(null);
    const idManageProfileMatchRef = useRef(null);
    //other useref
    const idmanageIdProfileDetailStarterRef = useRef(null);
    const phonemanageProfileDetailStarterRef = useRef(null);
    const originemanageProfileDetailStarterRef = useRef(null);
    const sexeformanageProfileDetailStarterRef = useRef(null);
    const prefermanageProfileDetailStarterRef = useRef(null);
    const seekmanageProfileDetailStarterRef = useRef(null);
    const contactmanageProfileDetailStarterRef = useRef(null);
    const usermanageProfileDetailStarterRef = useRef(null);
    const soulmatemanageProfileDetailStarterRef = useRef(null);
    //
    const idmanageProfileDetailMatchStarterRef = useRef(null);
    const idmanageIdProfileDetailMatchStarterRef = useRef(null);
    const sexeformanageProfileDetailMatchStarterRef = useRef(null);
    const seekmanageProfileDetailMatchStarterRef = useRef(null);
    const prefermanageProfileDetailMatchStarterRef = useRef(null);
    const originemanageProfileDetailMatchStarterRef = useRef(null);
    const contactmanageProfileDetailMatchStarterRef = useRef(null);
    const usermanageProfileDetailMatchStarterRef = useRef(null);
    const soulmatemanageProfileDetailMatchStarterRef = useRef(null);
    const phonemanageProfileDetailMatchStarterRef = useRef(null);
    //
    const analyticsText1Ref = useRef(null);
    const analyticsText2Ref = useRef(null);
    const matchPercentageRef = useRef(null);
    //selected profle
    const idStarterProfile1Ref = useRef(null);
    const idProfile1Ref = useRef(null);
    const soulmateProfile1Ref = useRef(null);
    const idStarterProfile2Ref = useRef(null);
    const idProfile2Ref = useRef(null);
    const soulmateProfile2Ref = useRef(null);
    const matchScorePercentageRef = useRef(null);

    async function profileListStatus(e){
        e.preventDefault();

        var resultat1 = 0;

        await Axios.post('https://rishtadminapi.carions.net/manage/profile').then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTexteNotAvailable1 = "No Data Available";
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable1, buttonHandler);
            }else{
                //console.log(response1.data);//comment before deploying
                setUserManageProfile(response1.data);
            }
        })
    }

    async function idManageProfileListHandler(e){
        e.preventDefault();

        const manageprofiledetail = document.getElementById('manageprofiledetail');
        var resultat2 = 0;

        const IdManageProfile = idManageProfileRef.current.value;
        await Axios.post('https://rishtadminapi.carions.net/manage/profile/detail', {
            IdManageProfile:IdManageProfile,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTexteNotAvailable2 = "No Data Available";
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable2, buttonHandler);
            }else{
                //console.log(response2.data);//comment before deploying
                manageprofiledetail.style.display = 'block';
                setUserManageProfileDetail(response2.data);
            }
        })
    }

    async function idNextManageProfileListHandler(e){
        e.preventDefault();

        document.getElementById('manageprofilematch').style.display = 'block';
        document.getElementById('analyticprofilematch').style.display = 'none';
        document.getElementById('saveprofilematch').style.display = 'none';
    }

    var SexeforSoulmateDetailStarter = '';
    async function manageprofileListMatch(e){
        e.preventDefault();

        const manageprofiledetailmatch = document.getElementById('manageprofiledetailmatch');
        var resultat3 = 0;
        
        const OrigineManageProfileDetailStarter = originemanageProfileDetailStarterRef.current.value;
        const SexeforManageProfileDetailStarter = sexeformanageProfileDetailStarterRef.current.value;
        const PreferManageProfileDetailStarter = prefermanageProfileDetailStarterRef.current.value;
        const SeekManageProfileDetailStarter = seekmanageProfileDetailStarterRef.current.value;

        //set if statement for origine and sexefor
        const PreferSoulmateDetailStarter = OrigineManageProfileDetailStarter;
        const OrigineSoulmateDetailStarter = PreferManageProfileDetailStarter;
        //var SexeforSoulmateDetailStarter = '';
        if(SexeforManageProfileDetailStarter === 'lookman'){
            SexeforSoulmateDetailStarter = 'lookwoman';
        }else if(SexeforManageProfileDetailStarter === 'lookwoman'){
            SexeforSoulmateDetailStarter = 'lookman';
        }

        //console.log(PreferSoulmateDetailStarter);//comment before deploying
        //console.log(OrigineSoulmateDetailStarter);//comment before deploying
        //console.log(SexeforSoulmateDetailStarter);//comment before deploying
        //console.log(SeekManageProfileDetailStarter);//comment before deploying

        await Axios.post('https://rishtadminapi.carions.net/manage/profile/match', {
            PreferSoulmateDetailStarter:PreferSoulmateDetailStarter,
            OrigineSoulmateDetailStarter:OrigineSoulmateDetailStarter,
            SexeforSoulmateDetailStarter:SexeforSoulmateDetailStarter,
            SeekManageProfileDetailStarter:SeekManageProfileDetailStarter,
        }).then((response3) => {
            resultat3 = response3.data.length;
            if(resultat3 === 0){
                const pTexteNotAvailable3 = "No Data Available";
                AlertModal(elementOutIdMatch, elementDropIdMatch, pTexteNotAvailable3, buttonHandlerMatch);
            }else{
                //console.log(response3.data);//comment before deploying
                manageprofiledetailmatch.style.display = 'block';
                setUserManageProfileMatch(response3.data);
            }
        })
    }

    async function idManageProfileMatchHandler(e){
        e.preventDefault();

        const manageprofiledetailmatch = document.getElementById('manageprofiledetailmatch');
        const analyticprofilematch = document.getElementById('analyticprofilematch');
        const selectprofilematch = document.getElementById('selectprofilematch');
        var resultat4 = 0;

        const IdManageProfileMatch = idManageProfileMatchRef.current.value;
        await Axios.post('https://rishtadminapi.carions.net/manage/profile/detail/match', {
            IdManageProfileMatch:IdManageProfileMatch,
        }).then((response4) => {
            resultat4 = response4.data.length;
            if(resultat4 === 0){
                const pTexteNotAvailable4 = "No Data Available. No profile for this user";
                AlertModal(elementOutIdMatch, elementDropIdMatch, pTexteNotAvailable4, buttonHandlerMatch);
                manageprofiledetailmatch.style.display = 'none';
                analyticprofilematch.style.display = 'none';
                selectprofilematch.style.display = 'none';
            }else{
                //console.log(response4.data);//comment before deploying
                manageprofiledetailmatch.style.display = 'block';
                setUserManageProfileDetailMatch(response4.data);
            }
        })
    }

    async function idNextManageProfileMatchListHandler(e){
        e.preventDefault();

       // document.getElementById('userprofilemanage').style.display = 'none';
        document.getElementById('manageprofilematch').style.display = 'block';
        document.getElementById('analyticprofilematch').style.display = 'block';
        document.getElementById('inputtext').style.display = 'none';
        document.getElementById('comparebutton').style.display = 'none';
        document.getElementById('matchpercentage').style.display = 'none';
        document.getElementById('saveprofilematch').style.display = 'none';
    }

    async function analyticsProfileListMatch(e){
        e.preventDefault();

        document.getElementById('manageprofilematch').style.display = 'block';
        document.getElementById('analyticprofilematch').style.display = 'block';
        document.getElementById('inputtext').style.display = 'block';
        document.getElementById('comparebutton').style.display = 'block';
        document.getElementById('matchpercentage').style.display = 'none';
        document.getElementById('saveprofilematch').style.display = 'none';

        analyticsText1Ref.current.value = soulmatemanageProfileDetailStarterRef.current.value;
        analyticsText2Ref.current.value = usermanageProfileDetailMatchStarterRef.current.value;
    }

    async function idAnalyticsProfileMatchHandler(e){
        e.preventDefault();

        document.getElementById('manageprofilematch').style.display = 'block';
        document.getElementById('analyticprofilematch').style.display = 'block';
        document.getElementById('inputtext').style.display = 'block';
        document.getElementById('comparebutton').style.display = 'block';
        document.getElementById('matchpercentage').style.display = 'block';
        document.getElementById('saveprofilematch').style.display = 'none';

        var text1 = analyticsText1Ref.current.value;
        var text2 = analyticsText2Ref.current.value;
        //console.log(text1);//comment before deploying
        //console.log(text2);//comment before deploying

        var similarity = checkSimilarity(text1, text2);
        //console.log(similarity);//comment before deploying
        matchPercentageRef.current.value = similarity+' %';
    }

    async function AnalyticsProfileMatchSelectHandler(e){
        e.preventDefault();

        document.getElementById('manageprofilematch').style.display = 'block';
        document.getElementById('analyticprofilematch').style.display = 'block';
        document.getElementById('inputtext').style.display = 'block';
        document.getElementById('comparebutton').style.display = 'block';
        document.getElementById('matchpercentage').style.display = 'block';
        document.getElementById('saveprofilematch').style.display = 'block';
        document.getElementById('saveprofilematchdetail').style.display = 'none';
        document.getElementById('savebutton').style.display = 'none';
    }

    async function AnalyticsProfileMatchUndoHandler(e){
        e.preventDefault();

        document.getElementById('manageprofilematch').style.display = 'block';
        document.getElementById('analyticprofilematch').style.display = 'none';
        document.getElementById('inputtext').style.display = 'none';
        document.getElementById('comparebutton').style.display = 'none';
        document.getElementById('matchpercentage').style.display = 'none';
        document.getElementById('saveprofilematch').style.display = 'none';
        document.getElementById('saveprofilematchdetail').style.display = 'none';
    }

    async function selectprofileListMatch(e){
        e.preventDefault();

        idStarterProfile1Ref.current.value = idmanageProfileDetailStarterRef.current.value;
        idProfile1Ref.current.value = idmanageIdProfileDetailStarterRef.current.value;
        soulmateProfile1Ref.current.value = soulmatemanageProfileDetailStarterRef.current.value;

        idStarterProfile2Ref.current.value = idmanageProfileDetailMatchStarterRef.current.value;
        idProfile2Ref.current.value = idmanageIdProfileDetailMatchStarterRef.current.value;
        soulmateProfile2Ref.current.value = usermanageProfileDetailMatchStarterRef.current.value;
        matchScorePercentageRef.current.value = matchPercentageRef.current.value;

        document.getElementById('manageprofilematch').style.display = 'block';
        document.getElementById('analyticprofilematch').style.display = 'block';
        document.getElementById('inputtext').style.display = 'block';
        document.getElementById('comparebutton').style.display = 'block';
        document.getElementById('matchpercentage').style.display = 'block';
        document.getElementById('saveprofilematch').style.display = 'block';
        document.getElementById('saveprofilematchdetail').style.display = 'block';
        document.getElementById('savebutton').style.display = 'block';
    }

    async function idSelectProfileMatchHandler(e){
        e.preventDefault();

        //var resultat5 = '';
        const manageprofilematch = document.getElementById('manageprofilematch');
        const analyticprofilematch = document.getElementById('analyticprofilematch');
        const inputtext = document.getElementById('inputtext');
        const comparebutton = document.getElementById('comparebutton');
        const matchpercentage = document.getElementById('matchpercentage');
        const saveprofilematch = document.getElementById('saveprofilematch');
        const saveprofilematchdetail = document.getElementById('saveprofilematchdetail');
        const savebutton = document.getElementById('savebutton');

        const idStarterProfile1 = idStarterProfile1Ref.current.value;
        const idProfile1 = idProfile1Ref.current.value;
        const soulmateProfile1 = soulmateProfile1Ref.current.value;
        const idStarterProfile2 = idStarterProfile2Ref.current.value;
        const idProfile2 = idProfile2Ref.current.value;
        const soulmateProfile2 = soulmateProfile2Ref.current.value;
        const matchScorePercentage = matchScorePercentageRef.current.value;

        await Axios.post('https://rishtadminapi.carions.net/save/profile/detail/match', {
            idStarterProfile1:idStarterProfile1,
            idProfile1:idProfile1,
            soulmateProfile1:soulmateProfile1,
            idStarterProfile2:idStarterProfile2,
            idProfile2:idProfile2,
            soulmateProfile2:soulmateProfile2,
            matchScorePercentage:matchScorePercentage,
        }).then(() => {
            const pTexteSuccessSave = "Profile match save successfully";
            AlertModal(elementOutIdSave, elementDropIdSave, pTexteSuccessSave, buttonHandlerSave);
            //window.location.reload(false);
            manageprofilematch.style.display = 'block';
            analyticprofilematch.style.display = 'block';
            inputtext.style.display = 'none';
            comparebutton.style.display = 'none';
            matchpercentage.style.display = 'none';
            saveprofilematch.style.display = 'none';
            saveprofilematchdetail.style.display = 'none';
            savebutton.style.display = 'none';
        })
    }

    async function AnalyticsProfileMatchRejectHandler(e){
        e.preventDefault();

        document.getElementById('manageprofilematch').style.display = 'block';
        document.getElementById('analyticprofilematch').style.display = 'block';
        document.getElementById('inputtext').style.display = 'none';
        document.getElementById('comparebutton').style.display = 'none';
        document.getElementById('matchpercentage').style.display = 'none';
        document.getElementById('saveprofilematch').style.display = 'none';
        document.getElementById('saveprofilematchdetail').style.display = 'none';
        document.getElementById('savebutton').style.display = 'none';
    }

    return ( 
        <ProfileContainer>
            <ProfileWidget>
                <div id="userprofilemanage">
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>User Profile Management</WidgetSmTitle>
                        <br/>
                        <div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <ButtonList onClick={profileListStatus}>View</ButtonList>
                            </div>
                        </div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <WidgetSmTable>
                                <thead>
                                    <tr className="widgetdatingTr">
                                        <WidgetSmTh>ID</WidgetSmTh>
                                        <WidgetSmTh>Username</WidgetSmTh>
                                        <WidgetSmTh>Looking</WidgetSmTh>
                                        <WidgetSmTh>%Match</WidgetSmTh>
                                    </tr>
                                </thead>
                                <tbody id='widgetSmTableTableBody'>
                                    {userManageProfile.map((val, i) => {
                                        return(
                                            <tr key={i}>
                                                <td>{val.id_profile}</td>
                                                <td>{val.username_starter}</td>
                                                <td>{val.sexefor_starter}</td>
                                                <td>{val.score_starter}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </WidgetSmTable>
                            <br/>
                            <div>
                                <div style={{display:'block', textAlign:'center'}}>
                                    <LargeInput type='text' ref={idManageProfileRef}  placeholder='Enter User ID'/>
                                </div>
                                <br/>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <ButtonList onClick={idManageProfileListHandler}>Validate</ButtonList>
                                </div>
                            </div>
                            <br/><br/>
                            <div id='manageprofiledetail'>
                                {userManageProfileDetail.map((valdetail, i) => {
                                    return(
                                        <WidgetLgContainer id='widgetlg' key={i}>
                                            <WidgetLgTitle>Users Profile Management details</WidgetLgTitle>
                                            <DivScrollerWidgetLg>
                                                <div>
                                                    <label>ID Starter 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetail.id_starter} ref={idmanageProfileDetailStarterRef} />
                                                    </div>
                                                    <label>ID Profile 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetail.id_profile} ref={idmanageIdProfileDetailStarterRef} />
                                                    </div>
                                                    <label>Phone Number</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetail.phone_starter} ref={phonemanageProfileDetailStarterRef} />
                                                    </div>
                                                    <label>User's Origin</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetail.origine_starter} ref={originemanageProfileDetailStarterRef} />
                                                    </div>
                                                    <label>Looking for</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetail.sexefor_starter} ref={sexeformanageProfileDetailStarterRef} />
                                                    </div>
                                                    <label>Prefer member from</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetail.prefer_starter} ref={prefermanageProfileDetailStarterRef} />
                                                    </div>
                                                    <label>Seek relationship</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetail.seek_starter} ref={seekmanageProfileDetailStarterRef} />
                                                    </div>
                                                    <label>Prefer contacted by</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetail.contact_starter} ref={contactmanageProfileDetailStarterRef} />
                                                    </div>
                                                    <label>User's profile</label>
                                                    <div>
                                                        <TextArea type="text" readOnly value={valdetail.user_profile} ref={usermanageProfileDetailStarterRef} />
                                                    </div>
                                                    <label>Soulmate's profile</label>
                                                    <div>
                                                        <TextArea type="text" readOnly value={valdetail.soulmate_profile} ref={soulmatemanageProfileDetailStarterRef} />
                                                    </div>
                                                    <br/>
                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                        <ButtonNext onClick={idNextManageProfileListHandler}>Next</ButtonNext>
                                                    </div>
                                                    <br/>
                                                </div>
                                            </DivScrollerWidgetLg>
                                        </WidgetLgContainer>
                                    )
                                })}
                            </div>

                            <div id="modaloutalertmanageprofile"></div>
                            <div id="backdropoutalertmanageprofile"></div>
                        </FormScrollerWidgetSm>
                    </WidgetSmContainer>
                </div>

                <div id='manageprofilematch'>
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>User Profile Match</WidgetSmTitle>
                        <br/>
                        <div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <ButtonList onClick={manageprofileListMatch}>View</ButtonList>
                            </div>
                        </div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <WidgetSmTable>
                                <thead>
                                    <tr className="widgetdatingTr">
                                        <WidgetSmTh>ID</WidgetSmTh>
                                        <WidgetSmTh>Username</WidgetSmTh>
                                        <WidgetSmTh>Looking</WidgetSmTh>
                                        <WidgetSmTh>%Match</WidgetSmTh>
                                    </tr>
                                </thead>
                                <tbody id='widgetSmTableTableBody'>
                                    {userManageProfileMatch.map((valmatch, j) => {
                                        return(
                                            <tr key={j}>
                                                <td>{valmatch.id_starter}</td>
                                                <td>{valmatch.username_starter}</td>
                                                <td>{valmatch.sexefor_starter}</td>
                                                <td>{valmatch.score_starter}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </WidgetSmTable>
                            <br/>
                            <div>
                                <div style={{display:'block', textAlign:'center'}}>
                                    <LargeInput type='text' ref={idManageProfileMatchRef}  placeholder='Enter User ID'/>
                                </div>
                                <br/>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <ButtonList onClick={idManageProfileMatchHandler}>Validate</ButtonList>
                                </div>
                             </div>
                            <br/><br/>
                            <div id="manageprofiledetailmatch">
                                {userManageProfileDetailMatch.map((valdetailmatch, k) => {
                                    return(
                                        <WidgetLgContainer id='widgetlg' key={k}>
                                            <WidgetLgTitle>Users Profile Match details</WidgetLgTitle>
                                            <DivScrollerWidgetLg>
                                                <div>
                                                    <label>ID Starter 2</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailmatch.id_starter} ref={idmanageProfileDetailMatchStarterRef} />
                                                    </div>
                                                    <label>ID Profile 2</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailmatch.id_profile} ref={idmanageIdProfileDetailMatchStarterRef} />
                                                    </div>
                                                    <label>Phone Number</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailmatch.phone_starter} ref={phonemanageProfileDetailMatchStarterRef} />
                                                    </div>
                                                    <label>User's Origin</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailmatch.origine_starter} ref={originemanageProfileDetailMatchStarterRef} />
                                                    </div>
                                                    <label>Looking for</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailmatch.sexefor_starter} ref={sexeformanageProfileDetailMatchStarterRef} />
                                                    </div>
                                                    <label>Prefer member from</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailmatch.prefer_starter} ref={prefermanageProfileDetailMatchStarterRef} />
                                                    </div>
                                                    <label>Seek relationship</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailmatch.seek_starter} ref={seekmanageProfileDetailMatchStarterRef} />
                                                    </div>
                                                    <label>Prefer contacted by</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailmatch.contact_starter} ref={contactmanageProfileDetailMatchStarterRef} />
                                                    </div>
                                                    <label>User's profile</label>
                                                    <div>
                                                        <TextArea type="text" readOnly value={valdetailmatch.user_profile} ref={usermanageProfileDetailMatchStarterRef} />
                                                    </div>
                                                    <label>Soulmate's profile</label>
                                                    <div>
                                                        <TextArea type="text" readOnly value={valdetailmatch.soulmate_profile} ref={soulmatemanageProfileDetailMatchStarterRef} />
                                                    </div>
                                                    <br/>
                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                        <ButtonNext onClick={idNextManageProfileMatchListHandler}>Next</ButtonNext>
                                                    </div>
                                                    <br/>
                                                </div>
                                            </DivScrollerWidgetLg>
                                        </WidgetLgContainer>
                                    )
                                })}
                            </div>

                            <div id="modaloutalertmanageprofilematch"></div>
                            <div id="backdropoutalertmanageprofilematch"></div>
                        </FormScrollerWidgetSm>
                    </WidgetSmContainer>
                </div>

                <div id='analyticprofilematch'>
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>Profiles Match Similarity</WidgetSmTitle>
                        <br/>
                        <div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <ButtonList onClick={analyticsProfileListMatch}>View</ButtonList>
                            </div>
                        </div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <div id='inputtext' style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                <div>
                                    <label>Profile Input 1</label>
                                    <br/>
                                    <TextArea type="text" readOnly ref={analyticsText1Ref} />
                                </div>
                                
                                <div>
                                    <label>Profile Input 2</label>
                                    <br/>
                                    <TextArea type="text" readOnly ref={analyticsText2Ref} />
                                </div>
                            </div>
                            <br/>
                            <div id='comparebutton'>
                                <br/>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <ButtonList onClick={idAnalyticsProfileMatchHandler}>Compare</ButtonList>
                                </div>
                            </div>
                            <br/>
                            <div id='matchpercentage'>
                                <div style={{display:'block', textAlign:'center'}}>
                                    <label>Two Profiles Matching Score</label>
                                    <br/>
                                    <LargeInput type='text' readOnly ref={matchPercentageRef}  placeholder='Enter User ID'/>
                                </div>
                                <br/>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <ButtonList onClick={AnalyticsProfileMatchSelectHandler}>Select</ButtonList>
                                    </div>
                                    
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <ButtonClear onClick={AnalyticsProfileMatchUndoHandler}>Undo</ButtonClear>
                                    </div>
                                </div>
                            </div>
                            <br/><br/>
                            <div id="modaloutalertmanageprofile"></div>
                            <div id="backdropoutalertmanageprofile"></div>
                        </FormScrollerWidgetSm>
                    </WidgetSmContainer>
                </div>

                <div id='saveprofilematch'>
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>Selected Profile Match</WidgetSmTitle>
                        <br/>
                        <div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <ButtonList onClick={selectprofileListMatch}>Open</ButtonList>
                            </div>
                        </div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <div id='saveprofilematchdetail'>
                                <label>ID Starter 1</label>
                                <div>
                                    <LargeInput type="text" readOnly ref={idStarterProfile1Ref} />
                                </div>
                                <label>ID Profile 1</label>
                                <div>
                                    <LargeInput type="text" readOnly ref={idProfile1Ref} />
                                </div>
                                <label>Soulmate's profile 1</label>
                                <div>
                                    <TextArea type="text" readOnly ref={soulmateProfile1Ref} />
                                </div>
                                <br/>
                                <hr/><hr/>
                                <br/>
                                <label>ID Starter 2</label>
                                <div>
                                    <LargeInput type="text" readOnly ref={idStarterProfile2Ref} />
                                </div>
                                <label>ID Profile 2</label>
                                <div>
                                    <LargeInput type="text" readOnly ref={idProfile2Ref} />
                                </div>
                                <label>Soulmate's profile 2</label>
                                <div>
                                    <TextArea type="text" readOnly ref={soulmateProfile2Ref} />
                                </div>
                                <br/>
                                <div style={{display:'block', textAlign:'center'}}>
                                    <label>Matching Score selected</label>
                                    <br/>
                                    <LargeInput type='text' readOnly ref={matchScorePercentageRef}  placeholder='Enter User ID'/>
                                </div>
                            </div>
                            <br/>
                            <div id='savebutton'>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <ButtonList onClick={idSelectProfileMatchHandler}>Save</ButtonList>
                                    </div>
                                    <br/>
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <ButtonClear onClick={AnalyticsProfileMatchRejectHandler}>Reject</ButtonClear>
                                    </div>
                                </div>
                            </div>
                            <br/><br/>
                            <div id="modaloutalertmanageprofilesave"></div>
                            <div id="backdropoutalertmanageprofilesave"></div>
                        </FormScrollerWidgetSm>
                    </WidgetSmContainer>
                </div>
            </ProfileWidget>
        </ProfileContainer>
    );
}

export default Profile;