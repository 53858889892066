import React from 'react-dom';
import './appointment.css';

import emailjs from '@emailjs/browser';
import {AiOutlineWhatsApp, AiFillSchedule} from 'react-icons/ai'

import { AlertModal, addCurrentDate, formatDateData } from '../../helperfunctions/Helper'

import { 
    AppointmentContainer,
    AppointmentWidget,
    ButtonList,
    ButtonNext,
    //ButtonClear,
    LargeInput,
    LargeSelect, 
    TextArea,
    DivScrollerWidgetLg,
} from './AppointmentStyles';

import { 
    WidgetLgContainer,
    //FormScrollerWidgetLg,
    WidgetLgTitle,
} from '../../components/widgetlg/WidgetLgStyles';

import { 
    WidgetSmContainer,
    WidgetSmTitle,
    FormScrollerWidgetSm,
    WidgetSmTable,
    WidgetSmTh,
} from '../../components/widgetsm/WidgetSmStyles';

import {useState, useRef} from 'react';
import Axios from 'axios';

//set globale variables
var idProfileOne = '';
var scoreMatch = '';
var dateAppointment = '';
var timeAppointment = '';

function UpdateAppointment() {

    //alert
    const elementOutIdDays = "modaloutalertmanagedaysupdate";
    const elementDropIdDays = "backdropoutalertmanagedaysupdate";
    const buttonHandlerDays = "ouiHandlerAlertManageDaysUpdateBtn";

    //alert
    const elementOutId = "modaloutalertmanageupdate";
    const elementDropId = "backdropoutalertmanageupdate";
    const buttonHandler = "ouiHandlerAlertManageUpdateBtn";
    //alert create appointment
    const elementOutIdUpdateDetail = "modaloutalertupdatedetail";
    const elementDropIdUpdateDetail = "backdropoutalertupdatedetail";
    const buttonHandlerUpdateDetail = "ouiHandlerAlertUpdateDetailBtn";
    //alert send email and sms
    const elementOutIdUpdateSend = "modaloutalertupdatesend";
    const elementDropIdUpdateSend = "backdropoutalertupdatesend";
    const buttonHandlerUpdateSend = "ouiHandlerAlertUpdateSendBtn";

    //create list manage profile
    const [userManageUpdate, setUserManageUpdate] = useState([]);
    const [userUpdateDetail1, setUserUpdateDetail1] = useState([]);

    const updateNumberDaysRef = useRef(null);
    const newDateUpdateRef = useRef(null);
    const idManageUpdateRef = useRef(null);
    const idManageUpdateUser1Ref = useRef(null);
    const usernameManageUpdateUser1Ref = useRef(null);
    const ageManageAppointUser1Ref = useRef(null);
    const locationManageAppointUser1Ref = useRef(null);
    const contactManageAppointUser1Ref = useRef(null);
    const emailManageAppointUser1Ref = useRef(null);
    const phoneManageAppointUser1Ref = useRef(null);
    //
    const formEmailUpdateUser1 = useRef(null);
    const idUpdateUserName1Ref = useRef(null);
    const idUpdateUser1EmailRef = useRef(null);
    const idUpdateUser1EmailSubjectRef = useRef(null);
    const updateContentEmailUser1Ref = useRef(null);
    const idUpdatePhoneNumber1Ref = useRef(null);
    const updateContentMessageUser1Ref = useRef(null);
    const idStarterUpdateUser1Ref = useRef(null);
    const idProfileUpdateUser1Ref = useRef(null);
    const scoreMatchUpdateUser1Ref = useRef(null);
    const dateUpdateUser1Ref = useRef(null);
    const timeUpdateUser1Ref = useRef(null);
    const phoneUpdateUser1Ref = useRef(null);
    const emailUpdateUser1Ref = useRef(null);

    async function viewUpdateAppointment(e){
        e.preventDefault();

        const viewupdate = document.getElementById('viewupdate');

        const validupdateNumberDays = updateNumberDaysRef.current.value;
        if(!validupdateNumberDays){
            const pTexteSelectDays = "Please select number days";
            AlertModal(elementOutIdDays, elementDropIdDays, pTexteSelectDays, buttonHandlerDays);
        }else{
            var numberDays = parseFloat(updateNumberDaysRef.current.value);
            var remindDate = addCurrentDate(numberDays);
            newDateUpdateRef.current.value = remindDate;
            const NewDateUpdate = newDateUpdateRef.current.value;
            //console.log(remindDate);//comment before deploying
            var resultat1 = 0; 
            var resultatResponse1 = [];

            await Axios.post('https://rishtadminapi.carions.net/manage/update', {
                NewDateUpdate: NewDateUpdate,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    const pTexteNotAvailable1 = "No Data Available";
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable1, buttonHandler);
                    viewupdate.style.display = 'none';
                }else{
                    //console.log(response1.data);//comment before deploying
                    resultatResponse1 = response1.data;
                    for(let i=0; i<resultatResponse1.length; i++){
                        idProfileOne = resultatResponse1[i].id_profile;
                        scoreMatch = resultatResponse1[i].score_match;
                        dateAppointment = resultatResponse1[i].date_appointment;
                        timeAppointment = resultatResponse1[i].time_appointment;
                    }
                    setUserManageUpdate(response1.data);

                    viewupdate.style.display = 'block';
                }
            })
        }
    }

    async function idManageUpdateHandler(e){
        e.preventDefault();

        const updatedetail = document.getElementById('updatedetail');
        var resultat2 = 0;

        const valididManageUpdateRef = idManageUpdateRef.current.value;
        if(!valididManageUpdateRef){
            const pTexteNotUser1 = "Please Enter ID user 1";
            AlertModal(elementOutIdUpdateDetail, elementDropIdUpdateDetail, pTexteNotUser1, buttonHandlerUpdateDetail);
        }else{
            const IdManageUpdate = idManageUpdateRef.current.value;

            //detail create user 1
            await Axios.post('https://rishtadminapi.carions.net/create/update/detail1', {
                IdManageUpdate:IdManageUpdate,
            }).then((response2) => {
                resultat2 = response2.data.length;
                if(resultat2 === 0){
                    const pTexteNotAvailable2 = "No Data Available";
                    AlertModal(elementOutIdUpdateDetail, elementDropIdUpdateDetail, pTexteNotAvailable2, buttonHandlerUpdateDetail);
                    updatedetail.style.display = 'none';
                }else{
                    //console.log(response2.data);//comment before deploying
                    setUserUpdateDetail1(response2.data);
                    updatedetail.style.display = 'block';
                }
            })
        }
    }

    async function idEmailManageUpdateUser1Handler(e){
        e.preventDefault();

        const sendupdateemail1 = document.getElementById('sendupdateemail1');
        const sendupdatesms1 = document.getElementById('sendupdatesms1');
        const saveupdate1 = document.getElementById('saveupdate1');

        idUpdateUser1EmailRef.current.value = emailManageAppointUser1Ref.current.value;
        idUpdateUser1EmailSubjectRef.current.value = "Update Appointment";
        idUpdateUserName1Ref.current.value = usernameManageUpdateUser1Ref.current.value;
        //idCreateUser1EmailFromRef.current.value = "iftiwed@gmail.com";

        sendupdateemail1.style.display = 'block';
        sendupdatesms1.style.display = 'none';
        saveupdate1.style.display = 'none';
    }

    async function idSmsManageUpdateUser1Handler(e){
        e.preventDefault();

        const sendupdateemail1 = document.getElementById('sendupdateemail1');
        const sendupdatesms1 = document.getElementById('sendupdatesms1');
        const saveupdate1 = document.getElementById('saveupdate1');

        //delete first number (0) from phone number
        var phoneNumber1 = phoneManageAppointUser1Ref.current.value;
        phoneNumber1 = phoneNumber1.substring(1);

        idUpdatePhoneNumber1Ref.current.value = '+44'+phoneNumber1;

        sendupdateemail1.style.display = 'none';
        sendupdatesms1.style.display = 'block';
        saveupdate1.style.display = 'none';
    }

    async function saveUpdate1(e){
        e.preventDefault();

        const sendupdateemail1 = document.getElementById('sendupdateemail1');
        const sendupdatesms1 = document.getElementById('sendupdatesms1');
        const saveupdate1 = document.getElementById('saveupdate1');

        idStarterUpdateUser1Ref.current.value = idManageUpdateUser1Ref.current.value;
        idProfileUpdateUser1Ref.current.value = idProfileOne;
        scoreMatchUpdateUser1Ref.current.value = scoreMatch;
        dateUpdateUser1Ref.current.value = formatDateData(dateAppointment);
        timeUpdateUser1Ref.current.value = timeAppointment;
        phoneUpdateUser1Ref.current.value = phoneManageAppointUser1Ref.current.value;
        emailUpdateUser1Ref.current.value = emailManageAppointUser1Ref.current.value;

        sendupdateemail1.style.display = 'none';
        sendupdatesms1.style.display = 'none';
        saveupdate1.style.display = 'block';
    }

    async function sendUpdateEmail1(e){
        e.preventDefault();

        const valIdUpdateContentEmailUser1 = updateContentEmailUser1Ref.current.value;
        if(!valIdUpdateContentEmailUser1){
            const pTexteNotContent1 = "Please fill the content box";
            AlertModal(elementOutIdUpdateSend, elementDropIdUpdateSend, pTexteNotContent1, buttonHandlerUpdateSend); 
        }else{
            emailjs.sendForm('service_13h6qy4', 'template_fo0wb04', formEmailUpdateUser1.current, 'kexcSHxytC2s72ec3').then((result) => {
                //console.log(result.text);
                const pTexteSuccess1 = "Message sent successfully";
                AlertModal(elementOutIdUpdateSend, elementDropIdUpdateSend, pTexteSuccess1, buttonHandlerUpdateSend); 
            }, (error) => {
                //console.log(error.text);
                const pTexteError1 = "Send Message failed. Retry";
                AlertModal(elementOutIdUpdateSend, elementDropIdUpdateSend, pTexteError1, buttonHandlerUpdateSend); 
            });
            e.target.reset();
        }
    }

    async function sendUpdateSms1(e){
        e.preventDefault();

        const validIdUpdatePhoneNumber1 = idUpdatePhoneNumber1Ref.current.value;
        const validUpdateContentMessageUser1 = updateContentMessageUser1Ref.current.value;
        if(!validIdUpdatePhoneNumber1){
            const pTexteNotMessage1 = "Please enter Phone number";
            AlertModal(elementOutIdUpdateSend, elementDropIdUpdateSend, pTexteNotMessage1, buttonHandlerUpdateSend); 
        }else if(!validUpdateContentMessageUser1){
            const pTexteNotMessage1 = "Please fill the message box";
            AlertModal(elementOutIdUpdateSend, elementDropIdUpdateSend, pTexteNotMessage1, buttonHandlerUpdateSend); 
        }else{
            const IdUpdatePhoneNumber1 = idUpdatePhoneNumber1Ref.current.value;
            const UpdateContentMessageUser1 = updateContentMessageUser1Ref.current.value;

            // Regex expression to remove all characters which are NOT alphanumeric 
            let number1 = IdUpdatePhoneNumber1.replace(/[^\w\s]/gi, "").replace(/ /g, "");

            // Appending the phone number to the URL
            let url1 = `https://web.whatsapp.com/send?phone=${number1}`;

            // Appending the message to the URL by encoding it
            url1 += `&text=${encodeURI(UpdateContentMessageUser1)}&app_absent=0`;

            // Open our newly created URL in a new tab to send the message
            window.open(url1);
        }
    }

    async function validateUpdate1(e){
        e.preventDefault();

        const sendupdateemail1 = document.getElementById('sendupdateemail1');
        const sendupdatesms1 = document.getElementById('sendupdatesms1');
        const saveupdate1 = document.getElementById('saveupdate1');

        const validDateUpdateUser1 = dateUpdateUser1Ref.current.value;
        const validTimeUpdateUser1 = timeUpdateUser1Ref.current.value;
        if(!validDateUpdateUser1){
            const pTexteNotMessage1 = "Please select new date";
            AlertModal(elementOutIdUpdateSend, elementDropIdUpdateSend, pTexteNotMessage1, buttonHandlerUpdateSend); 
        }else if(!validTimeUpdateUser1){
            const pTexteNotMessage1 = "Please select new time";
            AlertModal(elementOutIdUpdateSend, elementDropIdUpdateSend, pTexteNotMessage1, buttonHandlerUpdateSend); 
        }else{
            const IdStarterUpdateUser1 = idStarterUpdateUser1Ref.current.value;
            const DateUpdateUser1 = dateUpdateUser1Ref.current.value;
            const TimeUpdateUser1 = timeUpdateUser1Ref.current.value;

            await Axios.post('https://rishtadminapi.carions.net/create/update/appointment', {
                IdStarterUpdateUser1:IdStarterUpdateUser1,
                DateUpdateUser1:DateUpdateUser1,
                TimeUpdateUser1:TimeUpdateUser1,
            }).then(() => {
                const pTexteSuccess1 = "Appointment updated successfully";
                AlertModal(elementOutIdUpdateSend, elementDropIdUpdateSend, pTexteSuccess1, buttonHandlerUpdateSend); 
                sendupdateemail1.style.display = 'none';
                sendupdatesms1.style.display = 'none';
                saveupdate1.style.display = 'none';
            })
        }
    }

    return ( 
        <AppointmentContainer>
            <AppointmentWidget>
                <div id="userupdatemanage">
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>Update User Appointments</WidgetSmTitle>
                        <br/>
                        <div style={{display:'block',textAlign:'center'}}>
                            <div>
                                <LargeSelect id="updateNumberdays" name="updateNumberdays" ref={updateNumberDaysRef}>
                                    <option value=''>Select days number</option>
                                    <option value='1'>1 day</option>
                                    <option value='2'>2 days</option>
                                    <option value='3'>3 days</option>
                                    <option value='4'>4 days</option>
                                    <option value='5'>5 days</option>
                                    <option value='6'>6 days</option>
                                    <option value='7'>7 days</option>
                                </LargeSelect>
                            </div>
                            <div style={{display:'none'}}>
                                <LargeInput type="date" readOnly ref={newDateUpdateRef} />
                            </div>
                        </div>
                        <br/>
                        <div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <ButtonList onClick={viewUpdateAppointment}>View</ButtonList>
                            </div>
                        </div>

                        <div id="modaloutalertmanagedaysupdate"></div>
                        <div id="backdropoutalertmanagedaysupdate"></div>

                        <div id="modaloutalertmanageupdate"></div>
                        <div id="backdropoutalertmanageupdate"></div>
                        <br/>
                        <div id='viewupdate'>
                            <FormScrollerWidgetSm>
                                <br/>
                                <WidgetSmTable>
                                    <thead>
                                        <tr className="widgetdatingTr">
                                            <WidgetSmTh>ID</WidgetSmTh>
                                            <WidgetSmTh>Date</WidgetSmTh>
                                            <WidgetSmTh>%Match</WidgetSmTh>
                                            <WidgetSmTh>Phone</WidgetSmTh>
                                            <WidgetSmTh>Email</WidgetSmTh>
                                        </tr>
                                    </thead>
                                    <tbody id='widgetSmTableTableBody'>
                                        {userManageUpdate.map((val, i) => {
                                            return(
                                                <tr key={i}>
                                                    <td>{val.id_starter}</td>
                                                    <td>{formatDateData(val.date_appointment)}</td>
                                                    <td>{val.score_match}</td>
                                                    <td>{val.phone_starter}</td>
                                                    <td>{val.email_starter}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </WidgetSmTable>
                                <br/>
                                <div>
                                    <div style={{display:'block', textAlign:'center'}}>
                                        <LargeInput type='text' ref={idManageUpdateRef}  placeholder='Enter User ID'/>
                                    </div>
                                    <br/>
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <ButtonList onClick={idManageUpdateHandler}>Validate</ButtonList>
                                    </div>
                                </div>
                                <div id="modaloutalertupdatedetail"></div>
                                <div id="backdropoutalertupdatedetail"></div>
                            </FormScrollerWidgetSm>
                        </div>
                    </WidgetSmContainer>
                </div>

                <div id='updatedetail'>
                    <WidgetSmContainer id='widgetsm'>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                            <div style={{marginLeft:'15px'}}>
                                {userUpdateDetail1.map((valupdate1, j) => {
                                    return(
                                        <WidgetLgContainer id='widgetlg' key={j}>
                                            <WidgetLgTitle>User Update details</WidgetLgTitle>
                                            <DivScrollerWidgetLg>
                                                <div>
                                                    <label>ID User </label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.id_starter} ref={idManageUpdateUser1Ref} />
                                                    </div>
                                                    <label>Username</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.username_starter} ref={usernameManageUpdateUser1Ref} />
                                                    </div>
                                                    <label>Age User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.age_starter} ref={ageManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Location User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.location_starter} ref={locationManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Prefered contact User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.contact_starter} ref={contactManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Email User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.email_starter} ref={emailManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Phone User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valupdate1.phone_starter} ref={phoneManageAppointUser1Ref} />
                                                    </div>
                                                    <br/>
                                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idEmailManageUpdateUser1Handler}>Email</ButtonNext>
                                                        </div>

                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idSmsManageUpdateUser1Handler}>Sms</ButtonNext>
                                                        </div>

                                                        <div style={{marginRight:'15px'}}>
                                                            <ButtonList onClick={saveUpdate1}>Validate</ButtonList>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div id='sendupdateemail1'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Send Email User</WidgetSmTitle>
                                                            <br/>
                                                            <FormScrollerWidgetSm ref={formEmailUpdateUser1} onSubmit={sendUpdateEmail1}>
                                                                <br/>
                                                                <div>
                                                                    <label>User Name</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly name="user_name" ref={idUpdateUserName1Ref} />
                                                                    </div>
                                                                    <label>User Email</label>
                                                                    <div>
                                                                        <LargeInput type="email" readOnly name="user_email" ref={idUpdateUser1EmailRef} />
                                                                    </div>
                                                                    <label>Subject</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly name="subject" ref={idUpdateUser1EmailSubjectRef} />
                                                                    </div>
                                                                    <label>Email Content</label>
                                                                    <div>
                                                                        <TextArea type="text" name="message" ref={updateContentEmailUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList type='submit'>Send</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="sendupdatesms1">
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Send Message User</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <AiOutlineWhatsApp style={{fontSize:'30px', color:'green'}} />
                                                                <br/>
                                                                <div>
                                                                    <label>User Phone number</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={idUpdatePhoneNumber1Ref} />
                                                                    </div>
                                                                    <label>Message to User</label>
                                                                    <div>
                                                                        <TextArea type="text" ref={updateContentMessageUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={sendUpdateSms1}>Send SMS</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id='saveupdate1'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Update User Appointment</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <AiFillSchedule style={{fontSize:'30px', color:'green'}}/>
                                                                <br/>
                                                                <div>
                                                                    <label>Id Starter User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={idStarterUpdateUser1Ref} />
                                                                    </div>
                                                                    <label>Id Profile User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={idProfileUpdateUser1Ref} />
                                                                    </div>
                                                                    <label>Score match User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={scoreMatchUpdateUser1Ref} />
                                                                    </div>
                                                                    <label>Date Appointment User</label>
                                                                    <div>
                                                                        <LargeInput type="date" ref={dateUpdateUser1Ref} />
                                                                    </div>
                                                                    <label>Time Appointment User</label>
                                                                    <div>
                                                                        <LargeInput type="time" ref={timeUpdateUser1Ref} />
                                                                    </div>
                                                                    <label>Phone User</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={phoneUpdateUser1Ref} />
                                                                    </div>
                                                                    <label>Email User 1</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={emailUpdateUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={validateUpdate1}>Update</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="modaloutalertupdatesend"></div>
                                                    <div id="backdropoutalertupdatesend"></div>
                                                </div>
                                            </DivScrollerWidgetLg>
                                        </WidgetLgContainer>
                                    )
                                })}
                            </div>
                        </div>
                    </WidgetSmContainer>
                </div>
            </AppointmentWidget>
        </AppointmentContainer>
    );
}

export default UpdateAppointment;