import React from 'react-dom';
import './starter.css';

import { RouteLink } from '../helperfunctions/Helper';
import { AlertModal } from '../helperfunctions/Helper'

import { 
    StarterContainer,
    FormScroller, 
    TextTitle,
    TextLogin, 
    LargeInput, 
    ButtonLogin,
} from './StarterLoginStyles';

import { /*useState ,*/useRef } from 'react';
import Axios from 'axios';

function StarterLogin() {

    //alert
    const elementOutId = "modaloutalertadmin";
    const elementDropId = "backdropoutalertadmin";
    const buttonHandler = "ouiHandlerAlertAdminBtn";

    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const titleRef = useRef(null);

    //login handler
    async function loginHandler(event){
        event.preventDefault();

        //const adminsidebar = document.getElementById('adminsidebar');
        //const adminuserdiv = document.getElementById('adminuserdiv');
        //const adminlogin = document.getElementById('adminlogin');

        //check if input empty
        const validUsername = usernameRef.current.value;
        const validPassword = passwordRef.current.value;
        const validTitle = titleRef.current.value;
        var resultat = '';

        if(!validUsername){
            const pTexteUsername = "Enter your valid admin username";
            AlertModal(elementOutId, elementDropId, pTexteUsername, buttonHandler);
        }else if(!validPassword){
            const pTextePassword = "Enter your valid admin password";
            AlertModal(elementOutId, elementDropId, pTextePassword, buttonHandler);
        }else if(!validTitle){
            const pTexteTitle = "Enter your valid title or position";
            AlertModal(elementOutId, elementDropId, pTexteTitle, buttonHandler);
        }else{

            const theUsername = usernameRef.current.value;
            const thePassword = passwordRef.current.value;
            const theTitle = titleRef.current.value;
            //console.log(theUsername);
            //console.log(thePassword);
            //console.log(theTitle);

            await Axios.post('https://rishtadminapi.carions.net/login/get', {
                theUsername:theUsername,
                thePassword:thePassword,
                theTitle:theTitle,
            }).then((response1) => {
                //console.log(response1.data)
                resultat = response1.data.length;
                if(resultat === 0){
                    const pTexteInvalidate = "Invalid credentials";
                    AlertModal(elementOutId, elementDropId, pTexteInvalidate, buttonHandler);
                }else{
                    //callback function to route to link set.
                    const sideHref = '/sidebar';
                    RouteLink(event, sideHref);
                    //adminuserdiv.style.display = 'block';
                    //adminlogin.style.display = 'none';
                    
                    const usersHref = '/users';
                    RouteLink(event, usersHref);
                }
            });
        }
    };

    return ( 
        <StarterContainer id='adminlogin'>
            <FormScroller>
                <br/>
                <TextTitle>Login to Admin dashboard</TextTitle>
                <br/>
                <TextLogin>Username</TextLogin>
                <div style={{display:'block', textAlign:'center'}}>
                    <LargeInput type='text' ref={usernameRef} placeholder='Enter your admin username' />
                </div>
                <br/>
                <TextLogin>Password</TextLogin>
                <div style={{display:'block', textAlign:'center'}}>
                    <LargeInput type='password' ref={passwordRef}  placeholder='Enter your Admin Password'/>
                </div>
                <br/>
                <TextLogin>Title/Position</TextLogin>
                <div style={{display:'block', textAlign:'center'}}>
                    <LargeInput type='text' ref={titleRef}  placeholder='Enter your title/position'/>
                </div>
                <br/>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <ButtonLogin onClick={loginHandler}>Login</ButtonLogin>
                </div>

                <div id="modaloutalertadmin"></div>
                <div id="backdropoutalertadmin"></div>
            </FormScroller>
        </StarterContainer>
    );
}

export default StarterLogin;