import styled from 'styled-components';

export const ProfileContainer = styled.div`
    flex:4;
`;

export const ProfileWidget = styled.div`
    display:flex;
    margin:20px;
`;

export const ButtonList = styled.button`
    border: none;
    color: #2d6c85;
    display: flex;
    width: 100px;
    height: 30px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: #e5f4fa;
    cursor: pointer;
`;

export const ButtonNext = styled.button`
    border: none;
    color: white;
    display: flex;
    width: 100px;
    height: 30px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: red;
    cursor: pointer;
`;

export const ButtonClear = styled.button`
    border: none;
    color: #ff0000;
    display: flex;
    width: 100px;
    height: 30px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: #ffcccc;
    cursor: pointer;
`;

export const LargeInput = styled.input`
    width:300px;
    height:30px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:290px;
    }

    @media screen and (max-width: 768px){
        width:290px;
    }
`;

export const LargeSelect = styled.select`
    width:310px;
    height:35px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:210px;
    }

    @media screen and (max-width: 768px){
        width:240px;
    }
`;

export const TextArea = styled.textarea`
   width: 310px;
   height: 310px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
`;

export const DivScrollerWidgetLg = styled.div`
    width: 100%;
    height: 460px;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple red;
    scrollbar-width: thin;
    display: flex;
    justify-content: space-around;
`;