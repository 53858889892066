import React from 'react-dom';
import './notify.css';

import { AlertModal } from '../../helperfunctions/Helper'

//import WidgetLg from '../../components/widgetlg/WidgetLg';

import { 
    NotifyContainer,
    NotifyWidget,
    ButtonList,
    ButtonClear,
    LargeInput,
    LargeSelect, 
    TextArea,
} from './NotifyStyles';

import { 
    WidgetLgContainer,
    FormScrollerWidgetLg,
    WidgetLgTitle,
    /*WidgetLgTable, 
    WidgetLgTh,
    WidgetLgUser,
    WidgetLgDate,
    WidgetLgAmount,*/
} from '../../components/widgetlg/WidgetLgStyles';

import { 
    WidgetSmContainer,
    WidgetSmTitle,
    FormScrollerWidgetSm,
    WidgetSmTable,
    WidgetSmTh,
} from '../../components/widgetsm/WidgetSmStyles';

import {useState, useRef} from 'react';
import Axios from 'axios';

function Notify() {

    //alert
    const elementOutId = "modaloutalertnotify";
    const elementDropId = "backdropoutalertnotify";
    const buttonHandler = "ouiHandlerAlertNotifyBtn";
    //
    const elementOutIdReply = "modaloutalertreply";
    const elementDropIdReply = "backdropoutalertreply";
    const buttonHandlerReply = "ouiHandlerAlertReplyBtn";

    /*const Button = ({type}) =>{
        return <button className={'WidgetLgButton ' + type}>{type}</button>
    }*/

    //create list of user login data
    const [userStatus, setUserStatus] = useState([]);
    const [userStatusReply, setUserStatusReply] = useState([]);

    const CountryNotifyRef = useRef(null);

    //useref id
    const userIdStatusRef = useRef(null);
    //reply form ref
    const idReplyRef = useRef(null);
    const userReplyRef = useRef(null);
    const passwReplyRef = useRef(null);
    const phoneReplyRef = useRef(null);
    const originReplyRef = useRef(null);
    const lookingReplyRef = useRef(null);
    const memberReplyRef = useRef(null);
    const seekingReplyRef = useRef(null);
    const contactReplyRef = useRef(null);
    const scoreReplyRef = useRef(null);
    const processReplyRef = useRef(null);
    const textAreaReplyRef = useRef(null);

    async function userListStatus(e){
        e.preventDefault();


        const validCountryNotify = CountryNotifyRef.current.value;
        var resultat1 = 0;

        if(!validCountryNotify){
            const pTexteCountry = "Please select the country user currently live";
            AlertModal(elementOutId, elementDropId, pTexteCountry, buttonHandler);
        }else{
            const CountryNotify = CountryNotifyRef.current.value;
            //console.log(CountryNotify);//comment before deploying
            await Axios.post('https://rishtadminapi.carions.net/status', {
                CountryNotify:CountryNotify,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    const pTexteNotAvailable = "No Data Available";
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                }else{
                    //console.log(response1.data);//comment before deploying
                    setUserStatus(response1.data);
                }
            })
        }
    }

    async function idHandler(e){
        e.preventDefault();

        const replytouser = document.getElementById('replytouser');
        var resultat2 = 0;

        const validuserIdStatus = userIdStatusRef.current.value;
        if(!validuserIdStatus){
            const pTexteIdReply = "Please enter the id before validating";
            AlertModal(elementOutId, elementDropId, pTexteIdReply, buttonHandler);
        }else{
            const userIdStatus = userIdStatusRef.current.value;
            //console.log(userIdStatus);
            await Axios.post('https://rishtadminapi.carions.net/status/user', {
                userIdStatus:userIdStatus,
            }).then((response4) => {
                resultat2 = response4.data.length;
                if(resultat2 === 0){
                    const pTexteNotAvailableData = "No Data Available";
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailableData, buttonHandler);
                }else{
                    //console.log(response4.data);//comment before deploying
                    replytouser.style.display = 'block';
                    setUserStatusReply(response4.data);
                }
            }); 
        }
    }

    async function clearHandler(e){
        e.preventDefault();

        const replytouser = document.getElementById('replytouser');

        //clear the input for id
        userIdStatusRef.current.value = '';
        //clear all input
        idReplyRef.current.value = '';
        userReplyRef.current.value = '';
        passwReplyRef.current.value = '';
        phoneReplyRef.current.value = '';
        originReplyRef.current.value = '';
        lookingReplyRef.current.value = '';
        memberReplyRef.current.value = '';
        seekingReplyRef.current.value = ''; 
        contactReplyRef.current.value = '';
        scoreReplyRef.current.value = ''; 
        processReplyRef.current.value = '';
        textAreaReplyRef.current.value = '';

        replytouser.style.display = 'none';
    }

    async function processHandler(e){
        const countrynotifyStr = document.getElementById('countrynotify').value;
        const processReply = processReplyRef.current.value;
        //console.log(processReply);//comment before deploying
        if(countrynotifyStr === 'yes'){
            const welcome = ["Rishta welcomes you to the fruitful search for your long-lasting soul mate. However, we invite you to confirm your contacts below for the rest of your request if you wish to continue the process. The continuation of future personalized exchanges will be made via these channels in respect of your privacy while those of general notification will be on the rishta app."];
            if(processReply === 'welcome'){
                textAreaReplyRef.current.value = welcome;
            }
        }else if(countrynotifyStr === 'no'){
            const welcome = ["Rishta welcomes you to the fruitful search for your enduring soul mate. However, we confirm that only users living in the UK are allowed to be satisfied. We ask that you apply if only if you reside in the UK."];
            if(processReply === 'welcome'){
                textAreaReplyRef.current.value = welcome;
            }
        }
    }

    async function sendHandler(e){
        e.preventDefault();

        //check if some inputs empty
        const validprocess = processReplyRef.current.value;
        const validtextarea = textAreaReplyRef.current.value;
        if(!validprocess){
            const pTexteProcessType = "Please select the process type";
            AlertModal(elementOutIdReply, elementDropIdReply, pTexteProcessType, buttonHandlerReply);
        }else if(!validtextarea){
            const pTexteAreaEmpty = "Text Area is empty";
            AlertModal(elementOutIdReply, elementDropIdReply, pTexteAreaEmpty, buttonHandlerReply);
        }else{
            const idReply = idReplyRef.current.value;
            const userReply = userReplyRef.current.value;
            const passwReply = passwReplyRef.current.value;
            const phoneReply = phoneReplyRef.current.value;
            const origineReply = originReplyRef.current.value;
            const lookingReply = lookingReplyRef.current.value;
            const memberReply = memberReplyRef.current.value;
            const seekingReply = seekingReplyRef.current.value; 
            const contactReply = contactReplyRef.current.value;
            const scoreReply = scoreReplyRef.current.value; 
            const processReply = processReplyRef.current.value;
            const textAreaReply = textAreaReplyRef.current.value;

            Axios.post("https://rishtadminapi.carions.net/reply", {
                idReply:idReply,
                userReply:userReply,
                passwReply:passwReply,
                phoneReply:phoneReply,
                origineReply:origineReply,
                lookingReply:lookingReply,
                memberReply:memberReply,
                seekingReply:seekingReply,
                contactReply:contactReply,
                scoreReply:scoreReply,
                processReply:processReply,
                textAreaReply:textAreaReply,
            }).then(() => {
                const pTexteSuccessReply = "Reply to user successful";
                AlertModal(elementOutIdReply, elementDropIdReply, pTexteSuccessReply, buttonHandlerReply);
                window.location.reload(false);
                //clear the input for id
                userIdStatusRef.current.value = '';
                //clear all input
                idReplyRef.current.value = '';
                userReplyRef.current.value = '';
                passwReplyRef.current.value = '';
                phoneReplyRef.current.value = '';
                originReplyRef.current.value = '';
                lookingReplyRef.current.value = '';
                memberReplyRef.current.value = '';
                seekingReplyRef.current.value = ''; 
                contactReplyRef.current.value = '';
                scoreReplyRef.current.value = ''; 
                processReplyRef.current.value = '';
                textAreaReplyRef.current.value = '';
            });
        }
    }

    return ( 
        <NotifyContainer>
            <NotifyWidget>
                <div id="usernotifylist">
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>New join Members</WidgetSmTitle>
                        <br/>
                        <div style={{display:'block',textAlign:'center'}}>
                            <div>
                                <LargeSelect id="countrynotify" name="countrynotify" ref={CountryNotifyRef}>
                                    <option value=''>Living Country</option>
                                    <option value='yes'>Only UK</option>
                                    <option value='no'>Not UK</option>
                                </LargeSelect>
                            </div>
                        </div>
                        <br/>
                        <div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <ButtonList onClick={userListStatus}>View</ButtonList>
                            </div>
                        </div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <WidgetSmTable>
                                <thead>
                                    <tr className="widgetdatingTr">
                                        <WidgetSmTh>ID</WidgetSmTh>
                                        <WidgetSmTh>Username</WidgetSmTh>
                                        <WidgetSmTh>Gender</WidgetSmTh>
                                        <WidgetSmTh>%Match</WidgetSmTh>
                                    </tr>
                                </thead>
                                <tbody id='widgetSmTableTableBody'>
                                    {userStatus.map((val, i) => {
                                        return(
                                            <tr key={i}>
                                                <td>{val.id_starter}</td>
                                                <td>{val.username_starter}</td>
                                                <td>{val.sexe_starter}</td>
                                                <td>{val.score_starter}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </WidgetSmTable>
                            <br/>
                            <div>
                                <div style={{display:'block', textAlign:'center'}}>
                                    <LargeInput type='text' ref={userIdStatusRef}  placeholder='Enter User ID'/>
                                </div>
                                <br/>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <ButtonList onClick={idHandler}>Validate</ButtonList>
                                </div>
                            </div>
                        <br/><br/>
                        <div id="modaloutalertnotify"></div>
                        <div id="backdropoutalertnotify"></div>
                        </FormScrollerWidgetSm>
                    </WidgetSmContainer>
                </div> 

                <div id='replytouser'>
                    {userStatusReply.map((valreply, j) => {
                        return(
                            <WidgetLgContainer id='widgetlg' key={j}>
                                <WidgetLgTitle>Reply to users</WidgetLgTitle>
                                <FormScrollerWidgetLg>
                                    <div>
                                        <label>ID</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.id_starter} ref={idReplyRef} />
                                        </div>
                                        <label>Username</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.username_starter} ref={userReplyRef} />
                                        </div>
                                        <label>Password</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.password_starter} ref={passwReplyRef} />
                                        </div>
                                        <label>Phone number</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.phone_starter} ref={phoneReplyRef} />
                                        </div>
                                        <label>User origin</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.origine_starter} ref={originReplyRef} />
                                        </div>
                                        <label>Looking for</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.sexefor_starter} ref={lookingReplyRef} />
                                        </div>
                                        <label>Member origin from</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.prefer_starter} ref={memberReplyRef} />
                                        </div>
                                        <label>Seeking Relation</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.seek_starter} ref={seekingReplyRef} />
                                        </div>
                                        <label>Prefered contact</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.contact_starter} ref={contactReplyRef} />
                                        </div>
                                        <label>Dating match score</label>
                                        <div>
                                            <LargeInput type="text" readOnly value={valreply.score_starter} ref={scoreReplyRef} />
                                        </div>
                                    </div>
                                    <div>
                                        <label>Progress</label>
                                        <div>
                                            <LargeSelect id="process" name="process" ref={processReplyRef} onChange={processHandler}>
                                                <option value=''>Process...</option>
                                                <option value='welcome'>Welcome</option>
                                            </LargeSelect>
                                        </div>
                                        <label>Text Content</label>
                                        <div>
                                            <TextArea ref={textAreaReplyRef}></TextArea> 
                                        </div>
                                        <br/>
                                        <div style={{display:'flex', justifyContent:'space-around'}}>
                                            <div style={{display:'flex', justifyContent:'center'}}>
                                                <ButtonList onClick={sendHandler}>Send</ButtonList>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center'}}>
                                                <ButtonClear onClick={clearHandler}>Undo</ButtonClear>
                                            </div>
                                        </div>
                                    </div>
                                    <br/><br/>
                                </FormScrollerWidgetLg>
                                <div id="modaloutalertreply"></div>
                                <div id="backdropoutalertreply"></div>
                            </WidgetLgContainer>
                        )
                    })} 
                </div>     
            </NotifyWidget>
        </NotifyContainer>
    );
}

export default Notify;