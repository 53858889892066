import styled from 'styled-components';

export const TopBar = styled.div`
    width:100%;
    height:50px;
    background-color:white;
    position:sticky;
    top:0;
    z-index:999;
`;

export const TopBarWrapper = styled.div`
    height:100%;
    padding:0px 20px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`;

export const TextTitle = styled.div`
    margin-right: 550px;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    text-align: center;

    @media screen and (max-width: 640px){
        margin-right: 40px;
    }

    @media screen and (max-width: 768px){
        margin-right: 40px;
    }
`;

export const ImageLogo = styled.img`
    max-height:10vh;
    max-width:10vh;
    cursor:pointer;
`;

export const TopRight = styled.div`
    display:flex;
    align-items:center;
`;

export const TopAvatar = styled.img`
    width:40px;
    height:40px;
    border-radius:50%;
    cursor:pointer;
`;