import React from 'react-dom';
import './appointment.css';
import emailjs from '@emailjs/browser';
import {AiOutlineWhatsApp, AiFillSchedule} from 'react-icons/ai';

import Link from '../../components/Link';

import { AlertModal } from '../../helperfunctions/Helper'

import { 
    AppointmentContainer,
    AppointmentWidget,
    ButtonList,
    ButtonNext,
    //ButtonClear,
    LargeInput,
    //LargeSelect, 
    TextArea,
    DivScrollerWidgetLg,
} from './AppointmentStyles';

import { 
    WidgetLgContainer,
    //FormScrollerWidgetLg,
    WidgetLgTitle,
} from '../../components/widgetlg/WidgetLgStyles';

import { 
    WidgetSmContainer,
    WidgetSmTitle,
    FormScrollerWidgetSm,
    WidgetSmTable,
    WidgetSmTh,
} from '../../components/widgetsm/WidgetSmStyles';

import {useState, useRef} from 'react';
import Axios from 'axios';
//import { Email } from '@material-ui/icons';

//set globale variables
var idProfileOne = '';
var idProfileTwo = '';
var scoreMatch = '';

function Appointment() {

    //alert create appointment
    const elementOutId = "modaloutalertappointmentcreate";
    const elementDropId = "backdropoutalertappointmentcreate";
    const buttonHandler = "ouiHandlerAlertAppointmentCreateBtn";
    //alert create appointment
    const elementOutIdCreateDetail = "modaloutalertappointmentcreatedetail";
    const elementDropIdCreateDetail = "backdropoutalertappointmentcreatedetail";
    const buttonHandlerCreateDetail = "ouiHandlerAlertAppointmentCreateDetailBtn";
    //alert send email and sms
    const elementOutIdCreateSend = "modaloutalertappointmentcreatesend";
    const elementDropIdCreateSend = "backdropoutalertappointmentcreatesend";
    const buttonHandlerCreateSend = "ouiHandlerAlertAppointmentCreateSendBtn";

    //create appointment useRef
    const idAppointCreateUser1Ref = useRef(null);
    const idAppointCreateUser2Ref = useRef(null);

    //create appointment detail user 1 useRef
    const idManageAppointUser1Ref = useRef(null);
    const usernameManageAppointUser1Ref = useRef(null);
    const ageManageAppointUser1Ref = useRef(null);
    const locationManageAppointUser1Ref = useRef(null);
    const contactManageAppointUser1Ref = useRef(null);
    const emailManageAppointUser1Ref = useRef(null);
    const phoneManageAppointUser1Ref = useRef(null);

    //create appointment detail user 2 useRef
    const idManageAppointUser2Ref = useRef(null);
    const usernameManageAppointUser2Ref = useRef(null);
    const ageManageAppointUser2Ref = useRef(null);
    const locationManageAppointUser2Ref = useRef(null);
    const contactManageAppointUser2Ref = useRef(null);
    const emailManageAppointUser2Ref = useRef(null);
    const phoneManageAppointUser2Ref = useRef(null);
    //send email and sms detail user 1
    const formEmailUser1 = useRef();
    const idCreateUserName1Ref = useRef(null);
    const idCreateUser1EmailRef = useRef(null);
    const idCreateUser1EmailSubjectRef = useRef(null);
    const createContentEmailUser1Ref = useRef(null);
    const idCreatePhoneNumber1Ref = useRef(null);
    const createContentMessageUser1Ref = useRef(null);
    //send email and sms detail user 2
    const formEmailUser2 = useRef();
    const idCreateUserName2Ref = useRef(null);
    const idCreateUser2EmailRef = useRef(null);
    const idCreateUser2EmailSubjectRef = useRef(null);
    const createContentEmailUser2Ref = useRef(null);
    const idCreatePhoneNumber2Ref = useRef(null);
    const createContentMessageUser2Ref = useRef(null);
    //setup appointment user 1
    const idStarterUser1Ref = useRef(null);
    const idProfileUser1Ref = useRef(null);
    const scoreMatchUser1Ref = useRef(null);
    const dateAppointmentUser1Ref = useRef(null);
    const timeAppointmentUser1Ref = useRef(null);
    const phoneAppointmentUser1Ref = useRef(null);
    const emailAppointmentUser1Ref = useRef(null);
    //setup appointment user 2
    const idStarterUser2Ref = useRef(null);
    const idProfileUser2Ref = useRef(null);
    const scoreMatchUser2Ref = useRef(null);
    const dateAppointmentUser2Ref = useRef(null);
    const timeAppointmentUser2Ref = useRef(null);
    const phoneAppointmentUser2Ref = useRef(null);
    const emailAppointmentUser2Ref = useRef(null);

    //create list user appointment
    const [userAppointmentCreate, setUserAppointmentCreate] = useState([]);
    const [userAppointmentCreateDetail1, setUserAppointmentCreateDetail1] = useState([]);
    const [userAppointmentCreateDetail2, setUserAppointmentCreateDetail2] = useState([]);

    async function createAppointment(e){
        e.preventDefault();

        const viewcrudform = document.getElementById('viewcrudform');
        var resultat1 = 0;
        var resultatResponse1 = [];

        await Axios.post('https://rishtadminapi.carions.net/create/appointment').then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTexteNotAvailable1 = "No Appointment Available";
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable1, buttonHandler);
            }else{
                //console.log(response1.data);//comment before deploying
                resultatResponse1 = response1.data;
                for(let i=0; i<resultatResponse1.length; i++){
                    idProfileOne = resultatResponse1[i].id_profileone;
                    idProfileTwo = resultatResponse1[i].id_profiletwo;
                    scoreMatch = resultatResponse1[i].score_match;
                }
                setUserAppointmentCreate(response1.data);

                viewcrudform.style.display = 'block';
            }
        })
    }

    async function idAppointCreateHandler(e){
        e.preventDefault();

        const appointmentcreatedetail = document.getElementById('appointmentcreatedetail');
        var resultat2 = 0;
        var resultat3 = 0;

        const validIdAppointCreateUser1 = idAppointCreateUser1Ref.current.value;
        const validIdAppointCreateUser2 = idAppointCreateUser2Ref.current.value;

        if(!validIdAppointCreateUser1){
            const pTexteNotUser1 = "Please Enter ID user 1";
            AlertModal(elementOutIdCreateDetail, elementDropIdCreateDetail, pTexteNotUser1, buttonHandlerCreateDetail);
        }else if(!validIdAppointCreateUser2){
            const pTexteNotUser2 = "Please Enter ID user 2";
            AlertModal(elementOutIdCreateDetail, elementDropIdCreateDetail, pTexteNotUser2, buttonHandlerCreateDetail);
        }else{
            const IdAppointCreateUser1 = idAppointCreateUser1Ref.current.value;
            const IdAppointCreateUser2 = idAppointCreateUser2Ref.current.value;

            //detail create user 1
            await Axios.post('https://rishtadminapi.carions.net/create/appointment/detail1', {
                IdAppointCreateUser1:IdAppointCreateUser1,
            }).then((response2) => {
                resultat2 = response2.data.length;
                if(resultat2 === 0){
                    const pTexteNotAvailable2 = "No Data Available";
                    AlertModal(elementOutIdCreateDetail, elementDropIdCreateDetail, pTexteNotAvailable2, buttonHandlerCreateDetail);
                }else{
                    //console.log(response2.data);//comment before deploying
                    setUserAppointmentCreateDetail1(response2.data);
                }
            })

            //detail create user 2
            await Axios.post('https://rishtadminapi.carions.net/create/appointment/detail2', {
                IdAppointCreateUser2:IdAppointCreateUser2,
            }).then((response3) => {
                resultat3 = response3.data.length;
                if(resultat3 === 0){
                    const pTexteNotAvailable3 = "No Data Available";
                    AlertModal(elementOutIdCreateDetail, elementDropIdCreateDetail, pTexteNotAvailable3, buttonHandlerCreateDetail);
                }else{
                    //console.log(response3.data);//comment before deploying
                    setUserAppointmentCreateDetail2(response3.data);
                }
            })

            appointmentcreatedetail.style.display = 'block';
        }
    }

    async function idEmailManageAppointementUser1Handler(e){
        e.preventDefault();

        const sendcreateemail1 = document.getElementById('sendcreateemail1');
        const sendcreatesms1 = document.getElementById('sendcreatesms1');
        const savecreateappointment1 = document.getElementById('savecreateappointment1');

        idCreateUser1EmailRef.current.value = emailManageAppointUser1Ref.current.value;
        idCreateUser1EmailSubjectRef.current.value = "Notification";
        idCreateUserName1Ref.current.value = usernameManageAppointUser1Ref.current.value;
        //idCreateUser1EmailFromRef.current.value = "iftiwed@gmail.com";

        sendcreateemail1.style.display = 'block';
        sendcreatesms1.style.display = 'none';
        savecreateappointment1.style.display = 'none';
    }

    const sendEmail1 = (e) => {
        e.preventDefault();

        const valIdcreateContentEmailUser1 = createContentEmailUser1Ref.current.value;
        if(!valIdcreateContentEmailUser1){
            const pTexteNotContent1 = "Please fill the content box";
            AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteNotContent1, buttonHandlerCreateSend); 
        }else{
            emailjs.sendForm('service_13h6qy4', 'template_fo0wb04', formEmailUser1.current, 'kexcSHxytC2s72ec3').then((result) => {
                //console.log(result.text);
                const pTexteSuccess1 = "Message sent successfully";
                AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteSuccess1, buttonHandlerCreateSend); 
            }, (error) => {
                //console.log(error.text);
                const pTexteError1 = "Send Message failed. Retry";
                AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteError1, buttonHandlerCreateSend); 
            });
            e.target.reset();
        }
    };

    async function idSmsManageAppointementUser1Handler(e){
        e.preventDefault();

        const sendcreateemail1 = document.getElementById('sendcreateemail1');
        const sendcreatesms1 = document.getElementById('sendcreatesms1');
        const savecreateappointment1 = document.getElementById('savecreateappointment1');

        //delete first number (0) from phone number
        var phoneNumber1 = phoneManageAppointUser1Ref.current.value;
        phoneNumber1 = phoneNumber1.substring(1);

        idCreatePhoneNumber1Ref.current.value = '+44'+phoneNumber1;

        sendcreateemail1.style.display = 'none';
        sendcreatesms1.style.display = 'block';
        savecreateappointment1.style.display = 'none';
    }

    const sendSms1 = (e) => {
        e.preventDefault();

        const validIdCreatePhoneNumber1 = idCreatePhoneNumber1Ref.current.value;
        const validCreateContentMessageUser1 = createContentMessageUser1Ref.current.value;
        if(!validIdCreatePhoneNumber1){
            const pTexteNotMessage1 = "Please enter Phone number";
            AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteNotMessage1, buttonHandlerCreateSend); 
        }else if(!validCreateContentMessageUser1){
            const pTexteNotMessage1 = "Please fill the message box";
            AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteNotMessage1, buttonHandlerCreateSend); 
        }else{
            const IdCreatePhoneNumber1 = idCreatePhoneNumber1Ref.current.value;
            const CreateContentMessageUser1 = createContentMessageUser1Ref.current.value;

            // Regex expression to remove all characters which are NOT alphanumeric 
            let number1 = IdCreatePhoneNumber1.replace(/[^\w\s]/gi, "").replace(/ /g, "");

            // Appending the phone number to the URL
            let url1 = `https://web.whatsapp.com/send?phone=${number1}`;

            // Appending the message to the URL by encoding it
            url1 += `&text=${encodeURI(CreateContentMessageUser1)}&app_absent=0`;

            // Open our newly created URL in a new tab to send the message
            window.open(url1);
        }
    }

    async function saveAppointment1(e){
        e.preventDefault();

        const sendcreateemail1 = document.getElementById('sendcreateemail1');
        const sendcreatesms1 = document.getElementById('sendcreatesms1');
        const savecreateappointment1 = document.getElementById('savecreateappointment1');

        idStarterUser1Ref.current.value = idAppointCreateUser1Ref.current.value;
        idProfileUser1Ref.current.value = idProfileOne;
        scoreMatchUser1Ref.current.value = scoreMatch;
        phoneAppointmentUser1Ref.current.value = phoneManageAppointUser1Ref.current.value;
        emailAppointmentUser1Ref.current.value = emailManageAppointUser1Ref.current.value;

        sendcreateemail1.style.display = 'none';
        sendcreatesms1.style.display = 'none';
        savecreateappointment1.style.display = 'block';
    }

    async function validateAppointment1(e){
        e.preventDefault();

        const sendcreateemail1 = document.getElementById('sendcreateemail1');
        const sendcreatesms1 = document.getElementById('sendcreatesms1');
        const savecreateappointment1 = document.getElementById('savecreateappointment1');
        var resultat4 = '';

        const validDateAppointmentUser1 = dateAppointmentUser1Ref.current.value;
        const validTimeAppointmentUser1 = timeAppointmentUser1Ref.current.value;
        if(!validDateAppointmentUser1){
            const pTexteNotDate1 = "Please select the date";
            AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteNotDate1, buttonHandlerCreateSend); 
        }else if(!validTimeAppointmentUser1){
            const pTexteNotTime1 = "Please select time";
            AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteNotTime1, buttonHandlerCreateSend); 
        }else{
            const IdStarterUser1 = idStarterUser1Ref.current.value;
            const IdProfileUser1 = idProfileUser1Ref.current.value;
            const ScoreMatchUser1 =  scoreMatchUser1Ref.current.value;
            const DateAppointmentUser1 = dateAppointmentUser1Ref.current.value;
            const TimeAppointmentUser1 = timeAppointmentUser1Ref.current.value;
            const PhoneAppointmentUser1 = phoneAppointmentUser1Ref.current.value;
            const EmailAppointmentUser1 = emailAppointmentUser1Ref.current.value;

            await Axios.post('https://rishtadminapi.carions.net/check/appointmentone', {
                IdStarterUser1:IdStarterUser1,
            }).then((response4) =>{
                resultat4 = response4.data.length;
                if(resultat4 === 0){
                    Axios.post('https://rishtadminapi.carions.net/validate/appointmentone', {
                        IdStarterUser1:IdStarterUser1,
                        IdProfileUser1:IdProfileUser1,
                        ScoreMatchUser1:ScoreMatchUser1,
                        DateAppointmentUser1:DateAppointmentUser1,
                        TimeAppointmentUser1:TimeAppointmentUser1,
                        PhoneAppointmentUser1:PhoneAppointmentUser1,
                        EmailAppointmentUser1:EmailAppointmentUser1,
                    }).then(() => {
                        const pTexteSuccessValidated1 = "Appointment validated";
                        AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteSuccessValidated1, buttonHandlerCreateSend);
                        sendcreateemail1.style.display = 'none';
                        sendcreatesms1.style.display = 'none';
                        savecreateappointment1.style.display = 'none'; 
                    })
                }else{
                    const pTexteValidated1 = "Appointment already validated";
                    AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteValidated1, buttonHandlerCreateSend);
                    sendcreateemail1.style.display = 'none';
                    sendcreatesms1.style.display = 'none';
                    savecreateappointment1.style.display = 'none'; 
                }
            })
        }
    }

    async function idEmailManageAppointementUser2Handler(e){
        e.preventDefault();

        const sendcreateemail2 = document.getElementById('sendcreateemail2');
        const sendcreatesms2 = document.getElementById('sendcreatesms2');
        const savecreateappointment2 = document.getElementById('savecreateappointment2');

        idCreateUser2EmailRef.current.value = emailManageAppointUser2Ref.current.value;
        idCreateUser2EmailSubjectRef.current.value = "Notification";
        idCreateUserName2Ref.current.value = usernameManageAppointUser2Ref.current.value;
        //idCreateUser1EmailFromRef.current.value = "iftiwed@gmail.com";

        sendcreateemail2.style.display = 'block';
        sendcreatesms2.style.display = 'none';
        savecreateappointment2.style.display = 'none';
    }

    const sendEmail2 = (e) => {
        e.preventDefault();

        const valIdcreateContentEmailUser2 = createContentEmailUser2Ref.current.value;
        if(!valIdcreateContentEmailUser2){
            const pTexteNotContent2 = "Please fill the content box";
            AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteNotContent2, buttonHandlerCreateSend); 
        }else{
            emailjs.sendForm('service_13h6qy4', 'template_fo0wb04', formEmailUser2.current, 'kexcSHxytC2s72ec3').then((result) => {
                //console.log(result.text);
                const pTexteSuccess2 = "Message sent successfully";
                AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteSuccess2, buttonHandlerCreateSend); 
            }, (error) => {
                //console.log(error.text);
                const pTexteError2 = "Send Message failed. Retry";
                AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteError2, buttonHandlerCreateSend); 
            });
            e.target.reset();
        }
    };

    async function idSmsManageAppointementUser2Handler(e){
        e.preventDefault();

        const sendcreateemail2 = document.getElementById('sendcreateemail2');
        const sendcreatesms2 = document.getElementById('sendcreatesms2');
        const savecreateappointment2 = document.getElementById('savecreateappointment2');

        //delete first number (0) from phone number
        var phoneNumber2 = phoneManageAppointUser2Ref.current.value;
        phoneNumber2 = phoneNumber2.substring(1);

        idCreatePhoneNumber2Ref.current.value = '+44'+phoneNumber2;

        sendcreateemail2.style.display = 'none';
        sendcreatesms2.style.display = 'block';
        savecreateappointment2.style.display = 'none';
    }

    const sendSms2 = (e) => {
        e.preventDefault();

        const validIdCreatePhoneNumber2 = idCreatePhoneNumber2Ref.current.value;
        const validCreateContentMessageUser2 = createContentMessageUser2Ref.current.value;
        if(!validIdCreatePhoneNumber2){
            const pTexteNotMessage1 = "Please enter Phone number";
            AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteNotMessage1, buttonHandlerCreateSend); 
        }else if(!validCreateContentMessageUser2){
            const pTexteNotMessage2 = "Please fill the message box";
            AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteNotMessage2, buttonHandlerCreateSend); 
        }else{
            const IdCreatePhoneNumber2 = idCreatePhoneNumber2Ref.current.value;
            const CreateContentMessageUser2 = createContentMessageUser2Ref.current.value;

            // Regex expression to remove all characters which are NOT alphanumeric 
            let number2 = IdCreatePhoneNumber2.replace(/[^\w\s]/gi, "").replace(/ /g, "");

            // Appending the phone number to the URL
            let url2 = `https://web.whatsapp.com/send?phone=${number2}`;

            // Appending the message to the URL by encoding it
            url2 += `&text=${encodeURI(CreateContentMessageUser2)}&app_absent=0`;

            // Open our newly created URL in a new tab to send the message
            window.open(url2);
        }
    }

    async function saveAppointment2(e){
        e.preventDefault();

        const sendcreateemail2 = document.getElementById('sendcreateemail2');
        const sendcreatesms2 = document.getElementById('sendcreatesms2');
        const savecreateappointment2 = document.getElementById('savecreateappointment2');

        idStarterUser2Ref.current.value = idAppointCreateUser2Ref.current.value;
        idProfileUser2Ref.current.value = idProfileTwo;
        scoreMatchUser2Ref.current.value = scoreMatch;
        phoneAppointmentUser2Ref.current.value = phoneManageAppointUser2Ref.current.value;
        emailAppointmentUser2Ref.current.value = emailManageAppointUser2Ref.current.value;

        sendcreateemail2.style.display = 'none';
        sendcreatesms2.style.display = 'none';
        savecreateappointment2.style.display = 'block';
    }

    async function validateAppointment2(e){
        e.preventDefault();

        const sendcreateemail2 = document.getElementById('sendcreateemail2');
        const sendcreatesms2 = document.getElementById('sendcreatesms2');
        const savecreateappointment2 = document.getElementById('savecreateappointment2');
        var resultat5 = '';

        const validDateAppointmentUser2 = dateAppointmentUser2Ref.current.value;
        const validTimeAppointmentUser2 = timeAppointmentUser2Ref.current.value;
        if(!validDateAppointmentUser2){
            const pTexteNotDate2 = "Please select the date";
            AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteNotDate2, buttonHandlerCreateSend); 
        }else if(!validTimeAppointmentUser2){
            const pTexteNotTime2 = "Please select time";
            AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteNotTime2, buttonHandlerCreateSend); 
        }else{
            const IdStarterUser2 = idStarterUser2Ref.current.value;
            const IdProfileUser2 = idProfileUser2Ref.current.value;
            const ScoreMatchUser2 =  scoreMatchUser2Ref.current.value;
            const DateAppointmentUser2 = dateAppointmentUser2Ref.current.value;
            const TimeAppointmentUser2 = timeAppointmentUser2Ref.current.value;
            const PhoneAppointmentUser2 = phoneAppointmentUser2Ref.current.value;
            const EmailAppointmentUser2 = emailAppointmentUser2Ref.current.value;

            await Axios.post('https://rishtadminapi.carions.net/check/appointmenttwo', {
                IdStarterUser2:IdStarterUser2,
            }).then((response5) =>{
                resultat5 = response5.data.length;
                if(resultat5 === 0){
                    Axios.post('https://rishtadminapi.carions.net/validate/appointmenttwo', {
                        IdStarterUser2:IdStarterUser2,
                        IdProfileUser2:IdProfileUser2,
                        ScoreMatchUser2:ScoreMatchUser2,
                        DateAppointmentUser2:DateAppointmentUser2,
                        TimeAppointmentUser2:TimeAppointmentUser2,
                        PhoneAppointmentUser2:PhoneAppointmentUser2,
                        EmailAppointmentUser2:EmailAppointmentUser2,
                    }).then(() => {
                        const pTexteSuccessValidated2 = "Appointment validated";
                        AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteSuccessValidated2, buttonHandlerCreateSend);
                        sendcreateemail2.style.display = 'none';
                        sendcreatesms2.style.display = 'none';
                        savecreateappointment2.style.display = 'none'; 
                    })
                }else{
                    const pTexteValidated2 = "Appointment already validated";
                    AlertModal(elementOutIdCreateSend, elementDropIdCreateSend, pTexteValidated2, buttonHandlerCreateSend);
                    sendcreateemail2.style.display = 'none';
                    sendcreatesms2.style.display = 'none';
                    savecreateappointment2.style.display = 'none'; 
                }
            })
        }
    }

    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    /*async function reminderAppointment(e){
        //e.preventDefault();

        //
    }*/

    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    /*async function updateAppointment(e){
        //e.preventDefault();

        //
    }*/

    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    /*async function deleteAppointment(e){
        //e.preventDefault();

        //
    }*/

    return ( 
        <AppointmentContainer>
            <AppointmentWidget>
                <div id="userappointmentmanage">
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>User Appointments Management</WidgetSmTitle>
                        <br/>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                            <div style={{marginRight:'15px'}}>
                                <ButtonList onClick={createAppointment}>Create</ButtonList>
                            </div>
                            <div style={{marginRight:'15px'}}>
                                <ButtonList>
                                    <Link href="/reminder" className="item">
                                        Reminder
                                    </Link>
                                </ButtonList>
                            </div>
                            <div style={{marginRight:'15px'}}>
                                <ButtonList>
                                    <Link href="/appointmentupdate" className="item">
                                        Update
                                    </Link>
                                </ButtonList>
                            </div>
                            <div>
                                <ButtonList>
                                    <Link href="/appointmentdelete" className="item">
                                        Delete
                                    </Link>
                                </ButtonList>
                            </div>
                        </div>
                        <br/>
                        <div id='viewcrudform'>
                            <FormScrollerWidgetSm>
                                <br/>
                                <WidgetSmTable>
                                    <thead>
                                        <tr className="widgetdatingTr">
                                            <WidgetSmTh>Id Match</WidgetSmTh>
                                            <WidgetSmTh>Id User 1</WidgetSmTh>
                                            <WidgetSmTh>Id User 2</WidgetSmTh>
                                            <WidgetSmTh>%Match</WidgetSmTh>
                                        </tr>
                                    </thead>
                                    <tbody id='widgetSmTableTableBody'>
                                        {userAppointmentCreate.map((val, i) => {
                                            return(
                                                <tr key={i}>
                                                    <td>{val.id_match}</td>
                                                    <td>{val.id_starterone}</td>
                                                    <td>{val.id_startertwo}</td>
                                                    <td>{val.score_match}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </WidgetSmTable>
                                <br/>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                    <div style={{marginLeft:'15px'}}>
                                        <label>ID User 1</label>
                                        <br/>
                                        <LargeInput type='text' ref={idAppointCreateUser1Ref}  placeholder='Enter ID user 1'/>
                                    </div>
                                    <div style={{marginLeft:'15px'}}>
                                        <label>ID User 2</label>
                                        <br/>
                                        <LargeInput type='text' ref={idAppointCreateUser2Ref}  placeholder='Enter ID user 2'/>
                                    </div>
                                </div>
                                <br/>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <ButtonList onClick={idAppointCreateHandler}>Validate</ButtonList>
                                </div>

                                <div id="modaloutalertappointmentcreate"></div>
                                <div id="backdropoutalertappointmentcreate"></div>

                                <div id="modaloutalertappointmentcreatedetail"></div>
                                <div id="backdropoutalertappointmentcreatedetail"></div>
                            </FormScrollerWidgetSm>
                        </div>    
                    </WidgetSmContainer>
                </div>
                
                <div id='appointmentcreatedetail'>
                    <WidgetSmContainer id='widgetsm'>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                            <div style={{marginLeft:'15px'}}>
                                {userAppointmentCreateDetail1.map((valdetailcreate1, j) => {
                                    return(
                                        <WidgetLgContainer id='widgetlg' key={j}>
                                            <WidgetLgTitle>User 1 contact details</WidgetLgTitle>
                                            <DivScrollerWidgetLg>
                                                <div>
                                                    <label>ID User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate1.id_starter} ref={idManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Username 1 </label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate1.username_starter} ref={usernameManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Age User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate1.age_starter} ref={ageManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Location User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate1.location_starter} ref={locationManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Prefered contact User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate1.contact_starter} ref={contactManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Email User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate1.email_starter} ref={emailManageAppointUser1Ref} />
                                                    </div>
                                                    <label>Phone User 1</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate1.phone_starter} ref={phoneManageAppointUser1Ref} />
                                                    </div>
                                                    <br/>
                                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idEmailManageAppointementUser1Handler}>Email 1</ButtonNext>
                                                        </div>

                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idSmsManageAppointementUser1Handler}>Sms 1</ButtonNext>
                                                        </div>

                                                        <div style={{marginRight:'15px'}}>
                                                            <ButtonList onClick={saveAppointment1}>Save 1</ButtonList>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div id='sendcreateemail1'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Send Email User 1</WidgetSmTitle>
                                                            <br/>
                                                            <FormScrollerWidgetSm ref={formEmailUser1} onSubmit={sendEmail1}>
                                                                <br/>
                                                                <div>
                                                                    <label>User Name 1</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly name="user_name" ref={idCreateUserName1Ref} />
                                                                    </div>
                                                                    <label>User 1 Email</label>
                                                                    <div>
                                                                        <LargeInput type="email" readOnly name="user_email" ref={idCreateUser1EmailRef} />
                                                                    </div>
                                                                    <label>Subject</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly name="subject" ref={idCreateUser1EmailSubjectRef} />
                                                                    </div>
                                                                    <label>Email Content</label>
                                                                    <div>
                                                                        <TextArea type="text" name="message" ref={createContentEmailUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList type='submit'>Send</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="sendcreatesms1">
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Send Message User 1</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <AiOutlineWhatsApp style={{fontSize:'30px', color:'green'}} />
                                                                <br/>
                                                                <div>
                                                                    <label>User 1 Phone number</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={idCreatePhoneNumber1Ref} />
                                                                    </div>
                                                                    <label>Message to User 1</label>
                                                                    <div>
                                                                        <TextArea type="text" ref={createContentMessageUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={sendSms1}>Send SMS</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id='savecreateappointment1'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Confirm User 1 Appointment</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <AiFillSchedule style={{fontSize:'30px', color:'green'}}/>
                                                                <br/>
                                                                <div>
                                                                    <label>Id Starter User 1</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={idStarterUser1Ref} />
                                                                    </div>
                                                                    <label>Id Profile User 1</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={idProfileUser1Ref} />
                                                                    </div>
                                                                    <label>Score match User 1</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={scoreMatchUser1Ref} />
                                                                    </div>
                                                                    <label>Date Appointment User 1</label>
                                                                    <div>
                                                                        <LargeInput type="date" ref={dateAppointmentUser1Ref} />
                                                                    </div>
                                                                    <label>Time Appointment User 1</label>
                                                                    <div>
                                                                        <LargeInput type="time" ref={timeAppointmentUser1Ref} />
                                                                    </div>
                                                                    <label>Phone User 1</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={phoneAppointmentUser1Ref} />
                                                                    </div>
                                                                    <label>Email User 1</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={emailAppointmentUser1Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={validateAppointment1}>Validate</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="modaloutalertappointmentcreatesend"></div>
                                                    <div id="backdropoutalertappointmentcreatesend"></div>
                                                </div>
                                            </DivScrollerWidgetLg>
                                        </WidgetLgContainer>
                                    )
                                })}
                            </div>
                    
                            <div>
                                {userAppointmentCreateDetail2.map((valdetailcreate2, k) => {
                                    return(
                                        <WidgetLgContainer id='widgetlg' key={k}>
                                            <WidgetLgTitle>User 2 contact details</WidgetLgTitle>
                                            <DivScrollerWidgetLg>
                                                <div>
                                                    <label>ID User 2</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate2.id_starter} ref={idManageAppointUser2Ref} />
                                                    </div>
                                                    <label>Username 2 </label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate2.username_starter} ref={usernameManageAppointUser2Ref} />
                                                    </div>
                                                    <label>Age User 2</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate2.age_starter} ref={ageManageAppointUser2Ref} />
                                                    </div>
                                                    <label>Location User 2</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate2.location_starter} ref={locationManageAppointUser2Ref} />
                                                    </div>
                                                    <label>Prefered contact User 2</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate2.contact_starter} ref={contactManageAppointUser2Ref} />
                                                    </div>
                                                    <label>Email User 2</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate2.email_starter} ref={emailManageAppointUser2Ref} />
                                                    </div>
                                                    <label>Phone User 2</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valdetailcreate2.phone_starter} ref={phoneManageAppointUser2Ref} />
                                                    </div>
                                                    <br/>
                                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idEmailManageAppointementUser2Handler}>Email 2</ButtonNext>
                                                        </div>

                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idSmsManageAppointementUser2Handler}>Sms 2</ButtonNext>
                                                        </div>

                                                        <div style={{marginRight:'15px'}}>
                                                            <ButtonList onClick={saveAppointment2}>Save 2</ButtonList>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div id='sendcreateemail2'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Send Email User 2</WidgetSmTitle>
                                                            <br/>
                                                            <FormScrollerWidgetSm ref={formEmailUser2} onSubmit={sendEmail2}>
                                                                <br/>
                                                                <div>
                                                                    <label>User Name 2</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly name="user_name" ref={idCreateUserName2Ref} />
                                                                    </div>
                                                                    <label>User 2 Email</label>
                                                                    <div>
                                                                        <LargeInput type="email" readOnly name="user_email" ref={idCreateUser2EmailRef} />
                                                                    </div>
                                                                    <label>Subject</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly name="subject" ref={idCreateUser2EmailSubjectRef} />
                                                                    </div>
                                                                    <label>Email Content</label>
                                                                    <div>
                                                                        <TextArea type="text" name="message" ref={createContentEmailUser2Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList type='submit'>Send</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="sendcreatesms2">
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Send Message User 2</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <AiOutlineWhatsApp style={{fontSize:'30px', color:'green'}} />
                                                                <br/>
                                                                <div>
                                                                    <label>User 2 Phone number</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={idCreatePhoneNumber2Ref} />
                                                                    </div>
                                                                    <label>Message to User 2</label>
                                                                    <div>
                                                                        <TextArea type="text" ref={createContentMessageUser2Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={sendSms2}>Send SMS</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id='savecreateappointment2'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Confirm User 2 Appointment</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <AiFillSchedule style={{fontSize:'30px', color:'green'}}/>
                                                                <br/>
                                                                <div>
                                                                    <label>Id Starter User 2</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={idStarterUser2Ref} />
                                                                    </div>
                                                                    <label>Id Profile User 2</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={idProfileUser2Ref} />
                                                                    </div>
                                                                    <label>Score match User 2</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={scoreMatchUser2Ref} />
                                                                    </div>
                                                                    <label>Date Appointment User 2</label>
                                                                    <div>
                                                                        <LargeInput type="date" ref={dateAppointmentUser2Ref} />
                                                                    </div>
                                                                    <label>Time Appointment User 2</label>
                                                                    <div>
                                                                        <LargeInput type="time" ref={timeAppointmentUser2Ref} />
                                                                    </div>
                                                                    <label>Phone User 2</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={phoneAppointmentUser2Ref} />
                                                                    </div>
                                                                    <label>Email User 1</label>
                                                                    <div>
                                                                        <LargeInput type="text" readOnly ref={emailAppointmentUser2Ref} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={validateAppointment2}>Validate</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="modaloutalertappointmentcreatesend"></div>
                                                    <div id="backdropoutalertappointmentcreatesend"></div>
                                                </div>
                                            </DivScrollerWidgetLg>
                                        </WidgetLgContainer>
                                    )
                                })}
                            </div>
                        </div>
                    </WidgetSmContainer>
                </div>
            </AppointmentWidget>
        </AppointmentContainer>
    );
}

export default Appointment;