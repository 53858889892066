import styled from 'styled-components';

export const WidgetLgContainer = styled.div`
    flex:2;
    -webkit-box-shadow: 5px 5px 15px 5px #000000; 
    box-shadow: 5px 5px 15px 5px #000000;
    padding:20px;
`;

export const FormScrollerWidgetLg = styled.form`
    width: 100%;
    height: 460px;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple red;
    scrollbar-width: thin;
    display: flex;
    justify-content: space-around;
`;

export const WidgetLgTitle = styled.h3`
    font-size:20px;
    font-weight:600;
`;