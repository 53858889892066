import React from 'react-dom';
import './reports.css';
//import emailjs from '@emailjs/browser';
//import {AiOutlineWhatsApp, AiFillSchedule} from 'react-icons/ai';

import Link from '../../components/Link';

import { AlertModal, /*addCurrentDate,*/ formatDateData } from '../../helperfunctions/Helper';

import { 
    ReportsContainer,
    ReportsWidget,
    ButtonList,
    ButtonNext,
    //ButtonClear,
    LargeInput,
    LargeSelect, 
    TextArea,
    DivScrollerWidgetLg,
} from './ReportsStyles';

import { 
    WidgetLgContainer,
    //FormScrollerWidgetLg,
    WidgetLgTitle,
} from '../../components/widgetlg/WidgetLgStyles';

import { 
    WidgetSmContainer,
    WidgetSmTitle,
    FormScrollerWidgetSm,
    WidgetSmTable,
    WidgetSmTh,
} from '../../components/widgetsm/WidgetSmStyles';

import {useState, useRef} from 'react';
import Axios from 'axios';
//import { Email } from '@material-ui/icons';

//set globale variables
/*var idProfileOne = '';
var idProfileTwo = '';
var scoreMatch = '';*/
var IdReports = '';
var occupation = '';
var employer = '';
var startdate = '';
var position = '';

function Reports() {

    //alert
    const elementOutId = "modaloutalertview";
    const elementDropId = "backdropoutalertview";
    const buttonHandler = "ouiHandlerAlertViewBtn";

    //alert create appointment
    const elementOutIdViewDetail = "modaloutalertviewdetail";
    const elementDropIdViewDetail = "backdropoutalertviewdetail";
    const buttonHandlerViewDetail = "ouiHandlerAlertViewDetailBtn";

    //alert send email and sms
    const elementOutIdViewSend = "modaloutalertviewsend";
    const elementDropIdViewSend = "backdropoutalertviewsend";
    const buttonHandlerViewSend = "ouiHandlerAlertViewSendBtn";

    //create list user appointment
    const [userReportsCreate, setUserReportsCreate] = useState([]);
    const [userViewCreateDetail, setUserViewCreateDetail] = useState([]);
    //const [userAppointmentCreateDetail2, setUserAppointmentCreateDetail2] = useState([]);

    const idReportsCreateUserRef = useRef(null);
    const idPhoneCreateUserRef = useRef(null);
    //
    const idManageViewUserRef = useRef(null);
    const usernameManageViewUserRef = useRef(null);
    const ageManageViewUserRef = useRef(null);
    const locationManageViewUserRef = useRef(null);
    const contactManageViewUserRef = useRef(null);
    const emailManageViewUserRef = useRef(null);
    const phoneManageViewUserRef = useRef(null);
    //
    const userFirstnameRef = useRef(null);
    const userMiddlenameRef = useRef(null);
    const userLastnameRef = useRef(null);
    const userDatebirthRef = useRef(null);
    const userGenderRef = useRef(null);
    const userAddressRef = useRef(null);
    const userCityRef = useRef(null);
    const userPostCodeRef = useRef(null);
    const userDocumentRef = useRef(null);
    const userDocumentNumberRef = useRef(null);
    const userExpirationDateRef = useRef(null);
    //
    const userOccupationRef = useRef(null);
    const userEmployerRef = useRef(null);
    const userStartDateRef = useRef(null);
    const userPositionRef = useRef(null);
    //
    const contentAboutUserRef = useRef(null);
    const contentAboutSoulmateRef = useRef(null);

    async function ViewReports(e){
        e.preventDefault();

        const viewusersreports = document.getElementById('viewusersreports');
        var resultat1 = 0; 
        //var resultatResponse1 = [];

        await Axios.post('https://rishtadminapi.carions.net/reports/view').then((response1) =>{
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTexteNotAvailable1 = "No Data Available";
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable1, buttonHandler);
                viewusersreports.style.display = 'none';
            }else{
                //console.log(response1.data);//comment before deploying
                /*resultatResponse1 = response1.data;
                for(let i=0; i<resultatResponse1.length; i++){
                    idProfileOne = resultatResponse1[i].id_profile;
                    scoreMatch = resultatResponse1[i].score_match;
                    dateAppointment = resultatResponse1[i].date_appointment;
                    timeAppointment = resultatResponse1[i].time_appointment;
                }*/
                setUserReportsCreate(response1.data);

                viewusersreports.style.display = 'block';
            }
        })
    }

    async function idReportsCreateHandler(e){
        e.preventDefault();

        const viewdetail = document.getElementById('viewdetail');
        var resultat2 = 0;

        const valididReportsCreateUser = idReportsCreateUserRef.current.value;
        const valididPhoneCreateUser = idPhoneCreateUserRef.current.value;
        if(!valididReportsCreateUser){
            const pTexteNotUser1 = "Please Enter ID user";
            AlertModal(elementOutIdViewDetail, elementDropIdViewDetail, pTexteNotUser1, buttonHandlerViewDetail);
        }else if(!valididPhoneCreateUser){
            const pTextePhoneUser1 = "Please Enter user phone number";
            AlertModal(elementOutIdViewDetail, elementDropIdViewDetail, pTextePhoneUser1, buttonHandlerViewDetail);
        }else{
            const IdReportsCreateUser = idReportsCreateUserRef.current.value;
            const IdPhoneCreateUser = idPhoneCreateUserRef.current.value;

            await Axios.post('https://rishtadminapi.carions.net/create/view/detail', {
                IdReportsCreateUser:IdReportsCreateUser,
                IdPhoneCreateUser:IdPhoneCreateUser,
            }).then((response2) => {
                resultat2 = response2.data.length;
                if(resultat2 === 0){
                    const pTexteNotAvailable2 = "No Data Available";
                    AlertModal(elementOutIdViewDetail, elementDropIdViewDetail, pTexteNotAvailable2, buttonHandlerViewDetail);
                    viewdetail.style.display = 'none';
                }else{
                    //console.log(response2.data);//comment before deploying
                    setUserViewCreateDetail(response2.data);
                    viewdetail.style.display = 'block';
                }
            })
        }
    }

    async function idManageIdentityUserHandler(e){
        e.preventDefault();

        const useridentity = document.getElementById('useridentity');
        const viewoccupation = document.getElementById('viewoccupation');
        const viewprofile = document.getElementById('viewprofile');

        useridentity.style.display = 'block';
        viewoccupation.style.display = 'none';
        viewprofile.style.display = 'none';
    }

    async function idManageOccupationUserHandler(e){
        e.preventDefault();

        const useridentity = document.getElementById('useridentity');
        const viewoccupation = document.getElementById('viewoccupation');
        const viewprofile = document.getElementById('viewprofile');

        useridentity.style.display = 'none';
        viewoccupation.style.display = 'block';
        viewprofile.style.display = 'none';
    }

    async function IdManageProfileUserHandler(e){
        e.preventDefault();

        const useridentity = document.getElementById('useridentity');
        const viewoccupation = document.getElementById('viewoccupation');
        const viewprofile = document.getElementById('viewprofile');

        useridentity.style.display = 'none';
        viewoccupation.style.display = 'none';
        viewprofile.style.display = 'block';
    }

    async function saveUserDetailsHandler(e){
        e.preventDefault();

        const useridentity = document.getElementById('useridentity');
        const viewoccupation = document.getElementById('viewoccupation');
        const viewprofile = document.getElementById('viewprofile');
        //var resultat3 = 0;

        const validUserFirstname = userFirstnameRef.current.value;
        //const validUserMiddlename = userMiddlenameRef.current.value;
        const validUserLastname = userLastnameRef.current.value;
        const validUserDatebirth = userDatebirthRef.current.value;
        const validUserGender = userGenderRef.current.value;
        const validUserAddress = userAddressRef.current.value;
        const validUserCity = userCityRef.current.value;
        const validUserPostCode = userPostCodeRef.current.value;
        const validUserDocument = userDocumentRef.current.value;
        const validUserDocumentNumber = userDocumentNumberRef.current.value;
        const validUserExpirationDate = userExpirationDateRef.current.value;
        if(!validUserFirstname){
            const pTexteFirstname = "Please Enter Firstname";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteFirstname, buttonHandlerViewSend);
        }else if(!validUserLastname){
            const pTexteLastname = "Please Enter Lastname";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteLastname, buttonHandlerViewSend);
        }else if(!validUserDatebirth){
            const pTexteBirthdate = "Please Select Date of Birth";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteBirthdate, buttonHandlerViewSend);
        }else if(!validUserGender){
            const pTexteGender = "Please Select Gender";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteGender, buttonHandlerViewSend);
        }else if(!validUserAddress){
            const pTexteAddress = "Please Enter Address";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteAddress, buttonHandlerViewSend);
        }else if(!validUserCity){
            const pTexteCity = "Please Enter City";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteCity, buttonHandlerViewSend);
        }else if(!validUserPostCode){
            const pTextePostCode = "Please Enter Postcode";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTextePostCode, buttonHandlerViewSend);
        }else if(!validUserDocument){
            const pTexteUserDocument = "Please Enter Document Type";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteUserDocument, buttonHandlerViewSend);
        }else if(!validUserDocumentNumber){
            const pTexteUserDocumentNumber = "Please Enter Document Number";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteUserDocumentNumber, buttonHandlerViewSend);
        }else if(!validUserExpirationDate){
            const pTexteUserExpirationDate = "Please Enter Expiration Date";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteUserExpirationDate, buttonHandlerViewSend);
        }else{
            const IdReportsCreateUser = idReportsCreateUserRef.current.value;
            const UserFirstname = userFirstnameRef.current.value;
            const UserMiddlename = userMiddlenameRef.current.value;
            const UserLastname = userLastnameRef.current.value;
            const UserDatebirth = userDatebirthRef.current.value;
            const UserGender = userGenderRef.current.value;
            const UserAddress = userAddressRef.current.value;
            const UserCity = userCityRef.current.value;
            const UserPostCode = userPostCodeRef.current.value;
            const UserDocument = userDocumentRef.current.value;
            const UserDocumentNumber = userDocumentNumberRef.current.value;
            const UserExpirationDate = userExpirationDateRef.current.value;

            await Axios.post('https://rishtadminapi.carions.net/save/view/report', {
                IdReportsCreateUser:IdReportsCreateUser,
                UserFirstname:UserFirstname,
                UserMiddlename:UserMiddlename,
                UserLastname:UserLastname,
                UserDatebirth:UserDatebirth,
                UserGender:UserGender,
                UserAddress:UserAddress,
                UserCity:UserCity,
                UserPostCode:UserPostCode,
                UserDocument:UserDocument,
                UserDocumentNumber:UserDocumentNumber,
                UserExpirationDate:UserExpirationDate,
            }).then(() => {
                const pTexteIdentity = "Identity Added Successfully";
                AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteIdentity, buttonHandlerViewSend);
                useridentity.style.display = 'none';
                viewoccupation.style.display = 'none';
                viewprofile.style.display = 'none';
            })
        }

    }

    async function saveUserAddHandler(e){
        e.preventDefault();

        const useridentity = document.getElementById('useridentity');
        const viewoccupation = document.getElementById('viewoccupation');
        const viewprofile = document.getElementById('viewprofile');

        const validUserOccupation = userOccupationRef.current.value;
        const validUserEmployer = userEmployerRef.current.value;
        const validUserStartDate = userStartDateRef.current.value;
        const validUserPosition = userPositionRef.current.value;
        if(!validUserOccupation){
            const pTexteOccupation = "Please Select Employment situation";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteOccupation, buttonHandlerViewSend); 
        }else{
            if(validUserOccupation === 'unemployee'){
                IdReports = idReportsCreateUserRef.current.value;
                occupation = userOccupationRef.current.value;
                employer = "";
                startdate = "0000-00-00";
                position = "";
            }else{
                if(!validUserEmployer){
                    const pTexteEmployer = "Please Enter Employer Name";
                    AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteEmployer, buttonHandlerViewSend); 
                }else if(!validUserStartDate){
                    const pTexteStartDate = "Please Select Start Date";
                    AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteStartDate, buttonHandlerViewSend);
                }else if(!validUserPosition){
                    const pTextePosition = "Please Enter Position";
                    AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTextePosition, buttonHandlerViewSend); 
                }else{
                    IdReports = idReportsCreateUserRef.current.value;
                    occupation = userOccupationRef.current.value;
                    employer = userEmployerRef.current.value;
                    startdate = userStartDateRef.current.value;
                    position = userPositionRef.current.value;
                }
            }

            await Axios.post('https://rishtadminapi.carions.net/add/view/report', {
                IdReports:IdReports,
                UserOccupation:occupation,
                UserEmployer:employer,
                UserStartDate:startdate,
                UserPosition:position,
            }).then(() => {
                const pTexteAddOccupation = "Occupation Added Successfully";
                AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteAddOccupation, buttonHandlerViewSend); 
                useridentity.style.display = 'none';
                viewoccupation.style.display = 'none';
                viewprofile.style.display = 'none';
            })
        }
    }

    async function saveCompleteHandler(e){
        e.preventDefault();

        const useridentity = document.getElementById('useridentity');
        const viewoccupation = document.getElementById('viewoccupation');
        const viewprofile = document.getElementById('viewprofile');

        const validContentAboutUser = contentAboutUserRef.current.value;
        const validContentAboutSoulmate = contentAboutSoulmateRef.current.value;
        if(!validContentAboutUser){
            const pTexteAboutUser = "Please Fill Box About user";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteAboutUser, buttonHandlerViewSend); 
        }else if(!validContentAboutSoulmate){
            const pTexteSoulmate = "Please Fill Box Soulmate";
            AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteSoulmate, buttonHandlerViewSend); 
        }else{
            const IdReportsCreateUser = idReportsCreateUserRef.current.value;
            const ContentAboutUser = contentAboutUserRef.current.value;
            const ContentAboutSoulmate = contentAboutSoulmateRef.current.value;

            await Axios.post('https://rishtadminapi.carions.net/profile/view/report', {
                IdReportsCreateUser:IdReportsCreateUser,
                ContentAboutUser:ContentAboutUser,
                ContentAboutSoulmate:ContentAboutSoulmate,
            }).then(() => {
                const pTexteProfile = "Complete Profile Added Successfully";
                AlertModal(elementOutIdViewSend, elementDropIdViewSend, pTexteProfile, buttonHandlerViewSend); 
                useridentity.style.display = 'none';
                viewoccupation.style.display = 'none';
                viewprofile.style.display = 'none';
            })
        }
    }

    return ( 
        <ReportsContainer>
            <ReportsWidget>
                <div id="userreportsmanage">
                    <WidgetSmContainer id='widgetsm'>
                        <WidgetSmTitle>User Appointments Reports</WidgetSmTitle>
                        <br/>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                            <div style={{marginRight:'15px'}}>
                                <ButtonList onClick={ViewReports}>View</ButtonList>
                            </div>
                            <div style={{marginRight:'15px'}}>
                                <ButtonList>
                                    <Link href="/reportsupdate" className="item">
                                        Update
                                    </Link>
                                </ButtonList>
                            </div>
                            <div style={{marginRight:'15px'}}>
                                <ButtonList>
                                    <Link href="/reportsevaluation" className="item">
                                        Evaluation
                                    </Link>
                                </ButtonList>
                            </div>
                            <div style={{marginRight:'15px'}}>
                                <ButtonList>
                                    <Link href="/reportsmeeting" className="item">
                                        Meeting
                                    </Link>
                                </ButtonList>
                            </div>
                        </div>
                        <br/>
                        <div id='viewusersreports'>
                            <FormScrollerWidgetSm>
                                <br/>
                                <WidgetSmTable>
                                    <thead>
                                        <tr className="widgetdatingTr">
                                            <WidgetSmTh>Id</WidgetSmTh>
                                            <WidgetSmTh>Date</WidgetSmTh>
                                            <WidgetSmTh>Time</WidgetSmTh>
                                            <WidgetSmTh>Phone</WidgetSmTh>
                                        </tr>
                                    </thead>
                                    <tbody id='widgetSmTableTableBody'>
                                        {userReportsCreate.map((val, i) => {
                                            return(
                                                <tr key={i}>
                                                    <td>{val.id_starter}</td>
                                                    <td>{formatDateData(val.date_appointment)}</td>
                                                    <td>{val.time_appointment}</td>
                                                    <td>{val.phone_starter}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </WidgetSmTable>
                                <br/>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                    <div style={{marginLeft:'15px'}}>
                                        <label>ID User</label>
                                        <br/>
                                        <LargeInput type='text' ref={idReportsCreateUserRef}  placeholder='Enter ID user 1'/>
                                    </div>
                                    <div style={{marginLeft:'15px'}}>
                                        <label>User Phone</label>
                                        <br/>
                                        <LargeInput type='text' ref={idPhoneCreateUserRef}  placeholder='Enter ID user 2'/>
                                    </div>
                                </div>
                                <br/>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <ButtonList onClick={idReportsCreateHandler}>Validate</ButtonList>
                                </div>

                                <div id="modaloutalertview"></div>
                                <div id="backdropoutalertview"></div>

                                <div id="modaloutalertviewdetail"></div>
                                <div id="backdropoutalertviewdetail"></div>
                            </FormScrollerWidgetSm>
                        </div>
                    </WidgetSmContainer>
                </div>

                <div id='viewdetail'>
                    <WidgetSmContainer id='widgetsm'>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                            <div style={{marginLeft:'15px'}}>
                                {userViewCreateDetail.map((valview, j) => {
                                    return(
                                        <WidgetLgContainer id='widgetlg' key={j}>
                                            <WidgetLgTitle>User details</WidgetLgTitle>
                                            <DivScrollerWidgetLg>
                                                <div>
                                                    <label>ID User </label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valview.id_starter} ref={idManageViewUserRef} />
                                                    </div>
                                                    <label>Username</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valview.username_starter} ref={usernameManageViewUserRef} />
                                                    </div>
                                                    <label>Age User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valview.age_starter} ref={ageManageViewUserRef} />
                                                    </div>
                                                    <label>Location User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valview.location_starter} ref={locationManageViewUserRef} />
                                                    </div>
                                                    <label>Prefered contact User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valview.contact_starter} ref={contactManageViewUserRef} />
                                                    </div>
                                                    <label>Email User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valview.email_starter} ref={emailManageViewUserRef} />
                                                    </div>
                                                    <label>Phone User</label>
                                                    <div>
                                                        <LargeInput type="text" readOnly value={valview.phone_starter} ref={phoneManageViewUserRef} />
                                                    </div>
                                                    <br/>
                                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idManageIdentityUserHandler}>Identity</ButtonNext>
                                                        </div>

                                                        <div style={{display:'flex', justifyContent:'center'}}>
                                                            <ButtonNext onClick={idManageOccupationUserHandler}>Occupation</ButtonNext>
                                                        </div>

                                                        <div style={{marginRight:'15px'}}>
                                                            <ButtonList onClick={IdManageProfileUserHandler}>Profile</ButtonList>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <div id='useridentity'>
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>User Identity</WidgetSmTitle>
                                                            <br/>
                                                            <FormScrollerWidgetSm>
                                                                <br/>
                                                                <div>
                                                                    <label>First Name</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={userFirstnameRef} />
                                                                    </div>
                                                                    <label>Middle Name</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={userMiddlenameRef} />
                                                                    </div>
                                                                    <label>Last Name</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={userLastnameRef} />
                                                                    </div>
                                                                    <label>Date of Birth</label>
                                                                    <div>
                                                                        <LargeInput type="date" ref={userDatebirthRef} />
                                                                    </div>
                                                                    <label>Gender</label>
                                                                    <div>
                                                                        <LargeSelect ref={userGenderRef}>
                                                                            <option value="">Select Gender</option>
                                                                            <option value="Male">Male</option>
                                                                            <option value="Female">Female</option>
                                                                        </LargeSelect>
                                                                    </div>
                                                                    <label>Address</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={userAddressRef} />
                                                                    </div>
                                                                    <label>City</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={userCityRef} />
                                                                    </div>
                                                                    <label>Postcode</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={userPostCodeRef} />
                                                                    </div>
                                                                    <label>Document</label>
                                                                    <div>
                                                                        <LargeSelect ref={userDocumentRef}>
                                                                            <option value="">Select User Document</option>
                                                                            <option value="idcard">Id Card</option>
                                                                            <option value="passport">Passport</option>
                                                                            <option value="license">Driving License</option>
                                                                            <option value="others">others</option>
                                                                        </LargeSelect>
                                                                    </div>
                                                                    <label>Document Number</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={userDocumentNumberRef} />
                                                                    </div>
                                                                    <label>Expiration Date</label>
                                                                    <div>
                                                                        <LargeInput type="date" ref={userExpirationDateRef} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={saveUserDetailsHandler}>Save</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>

                                                    <div id="viewoccupation">
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>User Occupation</WidgetSmTitle>
                                                            <br/>
                                                            <FormScrollerWidgetSm>
                                                                <br/>
                                                                <div>
                                                                    <label>Occupation</label>
                                                                    <div>
                                                                        <LargeSelect ref={userOccupationRef}>
                                                                            <option value="">Select User Occupation</option>
                                                                            <option value="employee">Employee</option>
                                                                            <option value="unemployee">Unemployee</option>
                                                                            <option value="self">Self-employee</option>
                                                                            <option value="others">others</option>
                                                                        </LargeSelect>
                                                                    </div>
                                                                    <label>Employer</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={userEmployerRef} />
                                                                    </div>
                                                                    <label>Start Date</label>
                                                                    <div>
                                                                        <LargeInput type="date" ref={userStartDateRef} />
                                                                    </div>
                                                                    <label>Position</label>
                                                                    <div>
                                                                        <LargeInput type="text" ref={userPositionRef} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={saveUserAddHandler}>Add</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>

                                                    <div id="viewprofile">
                                                        <WidgetSmContainer id='widgetsm'>
                                                            <WidgetSmTitle>Complete User Profile</WidgetSmTitle>
                                                            <FormScrollerWidgetSm>
                                                                <br/>
                                                                <div>
                                                                    <label>About User</label>
                                                                    <div>
                                                                        <TextArea type="text" ref={contentAboutUserRef} />
                                                                    </div>
                                                                    <label>About Soulmate</label>
                                                                    <div>
                                                                        <TextArea type="text" ref={contentAboutSoulmateRef} />
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div>
                                                                    <div style={{display:'flex', justifyContent:'center'}}>
                                                                        <ButtonList onClick={saveCompleteHandler}>Complete</ButtonList>
                                                                    </div>
                                                                </div>
                                                            </FormScrollerWidgetSm>
                                                        </WidgetSmContainer>
                                                    </div>
                                                    <div id="modaloutalertviewsend"></div>
                                                    <div id="backdropoutalertviewsend"></div>
                                                </div>
                                            </DivScrollerWidgetLg>
                                        </WidgetLgContainer>
                                    )
                                })}
                            </div>
                        </div>
                    </WidgetSmContainer>
                </div>
            </ReportsWidget>
        </ReportsContainer>
    );
}

export default Reports;