import React from 'react-dom';
import './App.css';

import Route from './components/Route';
import Topbar from './components/topbar/Topbar';
import StarterLogin from './starter/StarterLogin';
import Sidebar from './components/sidebar/Sidebar';
import Home from './pages/home/Home';
import Users from './pages/users/Users';
import Notify from './pages/notify/Notify';
//import Replying from './pages/replying/Replying';
import Welcome from './pages/welcome/Welcome';
import Profile from './pages/profile/Profile';
//
import Appointment from './pages/appointment/Appointment';
import Reminder from './pages/appointment/Reminder';
import UpdateAppointment from './pages/appointment/UpdateAppointment';
import DeleteAppointment from './pages/appointment/DeleteAppointment';
//
import Reports from './pages/reports/Reports';

function App() {
  return (
    <div>
      <Topbar />
      <div className='container'>
        <Route path="/sidebar">
        <Sidebar />
        </Route>

        <Route path="/">
          <StarterLogin />
        </Route>

        <Route path="/home">
          <Home />
        </Route>

        <Route path="/users">
          <Users />
        </Route>

        <Route path="/notify">
          <Notify />
        </Route>

        {/*<Route path="/replying">
          <Replying />
        </Route>*/}

        <Route path="/welcome">
          <Welcome />
        </Route>

        <Route path="/profile">
          <Profile />
        </Route>

        <Route path="/appointment">
          <Appointment />
        </Route>

        <Route path="/reminder">
          <Reminder />
        </Route>

        <Route path="/appointmentupdate">
          <UpdateAppointment />
        </Route>

        <Route path="/appointmentdelete">
          <DeleteAppointment />
        </Route>

        <Route path="/reports-users">
          <Reports />
        </Route>
      </div>
    </div>
  );
}

export default App;
