import styled from 'styled-components';

export const StarterContainer = styled.div`
    flex: 4;
`;

export const FormScroller = styled.form`
    width: 40%;
    margin-left: 380px;
    background-color: #ffe6e6;
    height:400px;
    margin-top: 20px;
    border-radius: 10px;

    @media screen and (max-width: 640px){
        width:90%;
        height:400px;
        margin-left: 15px;
    }

    @media screen and (max-width: 768px){
        width:90%;
        height:400px;
        margin-left: 15px;
    }
`;

export const TextTitle = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    text-align: center;
`;

export const TextLogin = styled.span`
    width: 100%;
    font-size: 0.9rem;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
`;

export const LargeInput = styled.input`
    width:300px;
    height:30px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:290px;
    }

    @media screen and (max-width: 768px){
        width:290px;
    }
`;

export const ButtonLogin = styled.button`
    border: none;
    color: white;
    display: flex;
    width: 100px;
    height: 40px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: red;
    cursor: pointer;
`;