import React from 'react';
import image from './circle-heart.png';
//import image avatar template
import pakistan from './female_pakistan_200.png';

import { 
    TopBar, 
    TopBarWrapper, 
    TextTitle,
    ImageLogo, 
    TopRight, 
    TopAvatar,  
} from './TopbarStyles';

function Topbar() {
    return ( 
        <TopBar>
            <TopBarWrapper>
                <div className='topLeft'>
                    <ImageLogo src={image} alt='logo' />
                </div>
                <TopRight>
                    <TextTitle>Rishta Admin</TextTitle>
                    <TopAvatar src={pakistan} alt='avatar' />
                </TopRight>
            </TopBarWrapper>
        </TopBar>
    );
}

export default Topbar;