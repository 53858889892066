import styled from 'styled-components';

export const UserDiv = styled.div`
    height: 400; 
    width: 100%;
    flex:4;
    padding:20px;
`;

export const FormScroller = styled.form`
    width: 100%;
    background-color: #ffe6e6;
    height:500px;
    border-radius: 10px;
    overflow-y: scroll;

    @media screen and (max-width: 640px){
        width:100%;
        height:500px;
    }

    @media screen and (max-width: 768px){
        width:100%;
        height:500px;
    }
`;

export const TextDating = styled.span`
    width: 100%;
    font-size: 1.3rem;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
`;

export const WidgetDatingTable = styled.table`
    width:100%;
    border-spacing:20px;
`;

export const WidgetDatingTh = styled.th`
    text-align:left;
    color:#eb1b1b;
`;

export const WidgetDatingTd = styled.td`
    font-weight:300;
    color:#eb1b1b;
`;

export const TermsLabelDating = styled.span`
    width: 100%;
    font-size: 0.9rem;
    color:red;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
    margin:0;
`;

export const LargeInput = styled.input`
    width:300px;
    height:30px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:290px;
    }

    @media screen and (max-width: 768px){
        width:290px;
    }
`;

export const ButtonList = styled.button`
    border: none;
    color: white;
    display: flex;
    width: 200px;
    height: 30px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: red;
    cursor: pointer;
`;

export const LargeSelect = styled.select`
    width:310px;
    height:35px;
    border-radius:5px;
    font-size: 17px;

    @media screen and (max-width: 640px){
        width:210px;
    }

    @media screen and (max-width: 768px){
        width:240px;
    }
`;